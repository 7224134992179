import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slider1 from './Slider1';
import Slider2 from './Slider2';
import Slider3 from './Slider3';
import Slider4 from './Slider4';
import Slider5 from './Slider5';
import "./Slider.css"
import {Link} from 'react-router-dom';
import Slider6 from './Slider6';


export default function SliderView() {
  const settings = {
    autoplay: true,
    className: 'sample',
    dots: true,
    arrows: false,
    speed: 600,
    slickPause: true,
    autoplaySpeed: 6000,
    pauseOnDotsHover:true


  };
  return (
    <div className="app-padding app-top-margin slider">


      <Slider {...settings}>
      <Slider1 />

      <Slider2 />

      <Slider3 />


      <Slider4 />


      <Slider5 />
      <Slider6 />

      </Slider>
    </div>
  )
}
