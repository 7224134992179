import HomePageContainer from "../../containers/HomePageContainer/index";
import AboutBloodContainer from "../../containers/AboutBloodContainer/index";
import AboutUsContainer from "../../containers/AboutUsContainer/index";
import ContactUsContainer from "../../containers/ContactUs";
import FaqView from "../../views/faq";
import PrivacyPolicy from "../../views/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../../views/TermsAndConditions/TermsAndConditions";

const publicRoutesList = [
    {
      path: "/",
      path_: "/",
      restricted: false,
      private: false,
      component: HomePageContainer,
      name: "home",
      readablename: "home",
    },
    {
        path: "/about-blood",
        path_: "/about-blood",
        restricted: false,
        private: false,
        component:AboutBloodContainer ,
        name: "about-blood",
        readablename: "about-blood",
      },

      {
        path: "/about-us",
        path_: "/about-us",
        restricted: false,
        private: false,
        component: AboutUsContainer,
        name: "about-us",
        readablename: "about-us",
      },

      {
        path: "/contact-us",
        path_: "/contact-us",
        restricted: false,
        private: false,
        component: ContactUsContainer,
        name: "contact-us",
        readablename: "contact-us",
      },

      {
        path: "/faq",
        path_: "/faq",
        restricted: false,
        private: false,
        component: FaqView,
        name: "faq",
        readablename: "faq",
      },

      {
        path: "/faq/:type",
        path_: "/faq/:type",
        restricted: false,
        private: false,
        component: FaqView,
        name: "faq",
        readablename: "faq",
      },
      {
        path: "/privacy-policy.html",
        path_: "/privacy-policy",
        restricted: false,
        private: false,
        component: PrivacyPolicy,
        name: "privacy-policy",
        readablename: "privacy-policy",
      },

      {
        path: "/terms-and-conditions",
        path_: "/terms-and-conditions",
        restricted: false,
        private: false,
        component: TermsAndConditions,
        name: "terms-and-conditions",
        readablename: "terms-and-conditions",
      },
  ];


export { publicRoutesList };
