import React from 'react'
import Slider2 from "../../assets/images/BLUD_Mockup.png";
import PlaystoreLinks from '../PlaystoreLinks';

export default function Slider2View() {
    return (
<>

       
       <div className="slider2-grid"> 
       <div  className="position-relative h-100">
       <div className="left-content-div">
        <div className="main-heading">      Need 
        </div>
        <div className="main-heading main-sub-heading"> Blood, Plasma, Platelets?</div>
        <p>Your search ends here. Make a request through this website and<br/> track it on your mobile app until receipt / collection of blood.<br/>
The ‘Request to Donation Cycle’ is automated to help you.</p>
</div>
<div className="playstore-div">
   <PlaystoreLinks downloadApp={false}/>

  </div>
  </div>

  <div className="text-center slider2-img-div">
  <img className=" img-fluid slider-2-img m-auto" src={Slider2} /> 
  </div>
  <div className="slider-slogan slider-slogan2">
              <div className="slider-heading">
                # URGENT NEED
                  </div>

              <div className="slider-subheading"> GET IT IN FEW MINUTES</div>

              <div className="slider-subheading slider-subheading1">  REQUEST &gt; TRACK &gt; COLLECT</div>
  </div>

       
       </div>
      </>





    )
}


