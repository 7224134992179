import React, {useState} from "react";
import logo from "../assets/images/logo.png";
import menu from "../assets/images/hamburger.jpg";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Link} from "react-router-dom";
import {DialogContent} from "@material-ui/core";
import arrow from "../assets/images/arrow.png";
import qr from "../assets/images/qr.png";
import RequestBlood from "./ContactUs/RequestBlood";
import RequestBloodContainer from "../containers/RequestBlood";
export default function Header({history}) {
  const [formToggle, setFormToggle] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [msg, setMsg] = useState('');

  const routes = [
    {
      pathName: "Home",
      path: "/",
    },
    {
      pathName: "About Blood",
      path: "/about-blood",
    },
    {
      pathName: "FAQ",
      path: "/faq",
    },
    {
      pathName: "About Us",
      path: "/about-us",
    },
    {
      pathName: "Contact Us",
      path: "/contact-us",
    },
    // {
    //   pathName: "Institutions",
    //   path: "/institutions",
    // },
  ];


  const changeNavbarColor = () =>{
    if(window.scrollY >= 80){
      setColorchange(true);
    }
    else{
      setColorchange(false);
    }
 };

 window.addEventListener('scroll', changeNavbarColor);


 const toggleForm = (msg) => {
  setMsg(msg)
  if (formToggle) {
    setFormToggle(false)
  }
  else {
    setFormToggle(true)

  }

}

  return (
    <>
      <div>
        <nav className={`navbar app-padding navbar-expand-lg fixed-top ${colorChange ? ' colorChange' : ''}`} >
          <a className="navbar-brand" >
            <Link to="/"><img className="bd_logo" src={logo} /></Link>
            
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <img src={menu} />
  </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {routes.map((route, i) => {
                return (
                  <li className="nav-item" key={i}>
                    <Link
                      className={
                        "nav-link " +
                        (history.location.pathname === route.path ? "active" : "")
                      }
                      to={route.path}
                    >
                      {route.pathName}
                    </Link>
                    {history.location.pathname === route.path ? (
                      <div className="bd_active"></div>
                    ) : (
                      ""
                    )}
                  </li>

                );
              })}
            </ul>
            <button className="btn btn-primary mr-3"  onClick={() => toggleForm('Request Blood')}>Request Blood</button>
            <button className="btn btn-primary" >             <div className="d-flex justify-content-end ">
        <div  className="cursor-pointer">
          <a href="https://web.blud.app/signup" className="color-white">Register</a>
          </div>
          <div className="color-white mx-1">/</div>
        <div> <a  href="https://web.blud.app/login" className="color-white">Login</a></div>

    </div></button>

          </div>
        </nav>
      </div>

      
  {/* {formToggle ?  <RequestBloodContainer formToggle={formToggle} setFormToggle={toggleForm} /> : ''}     */}

  <Dialog aria-labelledby="simple-dialog-title" className="qr-code" maxWidth="400"

onClose={toggleForm} open={formToggle}>
<DialogTitle id="alert-dialog-title">{`Please install the Mobile App to ${msg}`}</DialogTitle>
<DialogContent>

  <div className="d-flex justify-content-between app-qr-code">
    <div>
      <div><img src={arrow} />Get Blood</div>
      <div><img src={arrow} />Track Availability</div>
      <div><img src={arrow} />Donate Blood</div>
      <div><img src={arrow} />Save Life!</div>

    </div>
    <img src={qr} height="150" />
  </div>
</DialogContent>

<DialogActions className="p-3 dialog-btns">
  <button onClick={()=>toggleForm(msg)} className="btn btn-primary">
    Close
  </button>
  {/* <button onClick={toggleForm} className="btn btn-outline-danger" autoFocus>
    Go to App Store
    </button> */}
</DialogActions>

</Dialog>
      
    </>
  );
}
