import React from "react";
import "./Footer.css";
import logo_dark from "../../assets/images/logo_dark.png";
import { Link } from "react-router-dom";
import Apple from "../../assets/images/Apple.png";
import Google from "../../assets/images/Google.png";
import youtube from "../../assets/images/youtube.png";
import instagram from "../../assets/images/instagram.png";
import linkedin from "../../assets/images/linkedin.png";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook.png";
import swadeshi from "../../assets/images/swadeshi.png";



export default function Footer() {
  const socialMediaList = [
    {
      img: facebook,
      link: "",
    },
    {
      img: instagram,
      link: "",
    },
    {
      img: twitter,
      link: "",
    },
    {
      img: linkedin,
      link: "",
    },
    {
      img: youtube,
      link: "", 
    },
  ];
  return (
    <footer className="pb-4 pt-5 mt-5">
      <div className="app-margin">
        <div className="row">
          <div className="col-4 col-xl-4 col-lg-5 col-md-6 col-sm-7 col-12 footer-column">
            <div className="d-flex justify-content-between">
              <div className="blud-info">
            <ul className="nav flex-column">
              <li >
                <img className="footer_logo" src={logo_dark} />
              </li>
              <li className="details">
              BLUD is a structured system for coordinated <br/>and sustainable sourcing of Blood, aimed to<br/> achieve Self Sufficiency in Blood Availability.<br/><b>Get Blood &gt; Donate Blood &gt; Save Life!</b>
              </li>
            </ul>

              </div>

              <div className=" text-center align-self-end swadeshi-div" >
              <img
                        className="img-fluid swadeshi-logo"
                        src={swadeshi}
                      />
              </div>
            </div>
          
            <div className="d-flex align-items-center my-2">
              {socialMediaList.map((item, i) => {
                return (
                  <Link to={item.link} key={i}>
                    <div className="position-relative mr-3">
                      <div className="social-media-circle"></div>
                      <img
                        className="social-media-icons center"
                        src={item.img}
                      />
                    </div>
                  </Link>
                );
              })}
            </div>
        

          </div>

    

            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12 pt-2">
            <p className="mb-0 fs-17" >Project:<span className="fw-600"> BLUD</span></p>
            <p className="mb-0"><b>Serapis Knowledge Solutions</b></p>
            <p className="address">Unit-16A, H&G House, Sector-11,<br/>  CBD Belapur, Navi Mumbai <br/> Maharashtra, India - 400 614 <br/>Tel: +91-22-27561508 / 09</p>
            </div>

          {/* <div className="col-4 footer-column row"> */}
            <div className="col-7 col-xl-3 col-lg-4 col-md-4 col-sm-4">
              <div className="d-flex justify-content-around">
                <div>
                <ul className="nav flex-column">
                <li >
                  <span className="footer-title">Company</span>
                </li>
                <li className="policy">
                  <Link to="/about-us">About us</Link>
                </li>
                <li className="policy">
                  <Link>SBS</Link>
                </li>
                <li className="policy">
                  <Link>
                    Penzyl<sup>TM</sup>
                  </Link>
                </li>
                <li className="policy">
                  <Link>Careers</Link>
                </li>
              </ul>
                </div>
                <div>
                <ul className="nav flex-column">
                <li className="policy">
                  <span className="footer-title">Support</span>
                </li>
                <li className="policy">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="policy">
                  <Link to="/terms-and-conditons">Terms Of Service</Link>
                </li>
                <li className="policy">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="policy">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
                </div>


              </div>
           
            </div>
         
            <div className="col-5 col-xl-2 col-lg-12 col-md-6 col-sm-5">
            <div className="footer-title my-2 d-none d-lg-block">Get the Apps</div>
              <ul className="nav get-app">
                <li className="d-block d-lg-none">
                  <span className="footer-title">Get the Apps</span>
                </li>
                <li className="mb-3 mr-2">
                  <Link>
                    <img className="download-app-img" src={Google} />
                  </Link>
                </li>
                <li className="mb-3">
                  <Link>
                    <img className="download-app-img" src={Apple} />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="d-flex text-center  col-12  my-3 flex-wrap justify-content-between copyright-div">
              <div className="policy">
                © Copyright 2020-2021. All rights reserved.
              </div>
              <div className=" policy"> <Link className="color-white" to="/privacy-policy.html">Privacy Policy</Link></div>

              <div className=" policy"><Link className="color-white" to="/terms-and-conditions">Terms Of Use</Link></div>
              <div className=" policy">CIN: U74999MH2019PTC330614</div>
              <div className=" policy">UAM: MH33D0170802</div>
              <div className=" policy">UDYAM-MH-33-0007588</div>
              <div className=" policy">Startup India: DIPP47390</div>

            </div>
          
          </div>
        {/* </div> */}


      
              <div className="storyset ml-3">Illustrations By Storyset</div>
      </div>
      
      
    </footer>
  );
}
