import React from 'react'
import "./TermsAndConditions.css"
import blud from "../../assets/images/logo.png";
export default function TermsAndConditions() {

    return (
        <div className="privacy-policy container">
            <div className="text-center">
                <img src={blud} style={{width: 100}} alt="logo" />
            </div>
            <h3 className="text-center fw-700 my-3">Terms & Conditions of Access & Use</h3>



            <p> STAKEHOLDERS/USERS ARE ADVISED TO PLEASE READ GENERAL AND SPECIAL TERMS AND CONDITIONS CAREFULLY BEFORE CLICKING THE BOX INDICATING YOUR ACCEPTANCE OR USING THE BLUD APPLICATION (APP) / WEBSITE AND / OR THE SERVICES AS DEFINED BELOW.</p>
            <p>THE APPLICATION BLUD AND WEBSITE WWW.BLUD.APP (HEREINAFTER REFERRED AS THE PLATFORM OR BLUD) AND THE INFORMATION, SERVICES AND OTHER MATERIALS CONTAINED THEREIN ARE PROVIDED AND OPERATED BY SERAPIS KNOWLEDGE SOLUTIONS PRIVATE LIMITED (REFERRED TO AS WE, US OR COMPANY OR SERAPIS). THE USE OF THE PLATFORM AND PROGRAMMES CONSTITUTES AN UNCONDITIONAL AGREEMENT TO FOLLOW AND USER SHALL BE BOUND BY THE TERMS.</p>
            <p>PLEASE REVIEW OUR TERMS OF USE, PRIVACY POLICY AND OTHER POLICIES AVAILABLE ON THE PLATFORM (COLLECTIVELY REFERRED TO AS THE TERMS) THAT GOVERN THE USE OF THE PLATFORM AND PROGRAMMES. EACH PROGRAMME MAY HAVE A SEPARATE SET OF TERMS DEALING WITH PAYMENTS, REFUNDS, DEFERRALS, ETC. GOVERNING SUCH PROGRAMMES, AND OUR CORPORATE CLIENTS MAY HAVE EXECUTED SEPARATE WRITTEN AGREEMENTS WITH US, WHICH, IN THE EVENT OF A CONFLICT, WILL SUPERSEDE THESE TERMS TO THE EXTENT OF THE CONFLICTING PROVISIONS.</p>
            <p>THESE TERMS MAY BE CHANGED FROM TIME TO TIME WITHOUT PRIOR NOTICE. USER SHOULD REVIEW THIS PAGE REGULARLY. WHETHER YOU HAVE ENROLLED IN A PROGRAMME OR ARE SIMPLY BROWSING THE PLATFORM, ONCE YOU HAVE ACCESSED THE PLATFORM OR PROGRAMMES, YOU SHALL BE CONSIDERED A USER FOR THE PURPOSE OF THESE TERMS. YOU ARE RESPONSIBLE FOR ALL YOUR ACTIVITIES IN CONNECTION WITH THE USE OF THE PLATFORM AND PROGRAMMES. YOU HEREBY AGREE TO FULLY COMPLY WITH ALL APPLICABLE LOCAL, PROVINCIAL, STATE, NATIONAL AND FOREIGN LAWS, TREATIES AND REGULATIONS IN CONNECTION WITH SUCH USE. YOU SHALL NOT RESORT TO ANY UNETHICAL PRACTICES WHILE USING THE PLATFORM.</p>
            <p>WITHOUT LIMITATION, YOU WILL NOT POST OR TRANSMIT, OR CAUSE TO BE POSTED OR TRANSMITTED, ANY COMMUNICATION OR SOLICITATION OR OTHER PHISHING, PHARMING OR WHALING MESSAGE DESIGNED OR INTENDED TO OBTAIN PASSWORD, ACCOUNT, PERSONAL INFORMATION, CONFIDENTIAL INFORMATION OR PRIVATE INFORMATION FROM ANY USER OF THE PLATFORM OR ANY OTHER THIRD PARTY WHATSOEVER. HOWEVER, IF ANY SUCH EVENT DOES TAKE PLACE, BLUD SHALL NOT BE LIABLE FOR ANY LOSS OF DATA OR IF THE USER’S DEVICE, COMPUTER OR ANY OTHER PROPERTY IS COMPROMISED IN ANY MANNER.</p>
            <p>YOU ACKNOWLEDGE AND AGREE THAT SERAPIS HAS THE RIGHT TO REPORT ANY AND ALL SUSPICIOUS OR ILLEGAL ACTIVITY TO THE APPROPRIATE LEGAL OR POLICE AUTHORITIES WITHOUT NOTICE TO YOU.</p>
            <p>BY USING THE SERVICES, YOU (A) AGREE TO THE TERMS EITHER YOUR OWN OR ON BEHALF OF YOUR COMPANY OR OTHER LEGAL ENTITY (CUSTOMER) AND REPRESENT THAT YOU HAVE THE AUTHORITY TO DO THAT.</p>
            <p>IF THIS IS NOT WHAT YOU INTEND, OR IF YOU DO NOT FULLY UNDERSTAND AND AGREE WITH ANY OR ALL OF THESE TERMS AND CONDITIONS AND GENERL TERMS AND CONDITIONS, THEN DO NOT CLICK THE BOX INDICATING YOUR ACCEPTANCE AND DO NOT ACCESS ANY BLUD WEBSITE OR USE THE BLUD APP / BLUD APP / PORTAL OR SERVICES.</p>

            <h6 className="primary-color">1.DEFINITIONS</h6>

            <p>Affiliate means any entity, which controls, is controlled by, or is under common control with Customer or with BLUD, as applicable.</p>
            <p>Agreement means the combination of these Terms, the Privacy Policy, and any referenced addendums, amendments, exhibits, Service Orders, schedules, SOWs, and/or other contract documents.</p>
            <p>BLUD Services means the method of using and accessing data through the BLUD APP / WEBSITE / PORTAL.</p>
            <p>Donor means any BLUD app user, who voluntarily donates Blood and Blood Components with the intent of saving life and as an altruistic act of philanthropy. Anyone who gives Blood and Blood Components for a transaction of cash or kind is NOT considered as a Donor. It may be prohibited by law in some places.</p>
            <p>Patient means any individual admitted in a medical facility for treatment of a disease or ailment and that requires Blood and/or Blood Components as a transfusion medicine to be sought from Blood Banks or eligible Donors.</p>
            <p>Data means any data transmitted to, from, and/or through any BLUD service including, without limitation, the BLUD APP / WEBSITE / PORTAL, electronic data exchange, communication and distribution of data supplied by the Parties from any source, including, without limitation, proprietary BLUD data, Customer data, Carrier data, and/or public data, including, without limitation, any data related to BLUD.</p>
            <p>SERAPIS, We, or Us means Serapis Knowledge Solutions Private Limited as an entity and BLUD application / Website / Portal and their derivatives and components as its products.</p>
            <p>BLUD Services means all sorts of services facilitating the users with the supply chain management of ‘demand & supply of Blood and Blood Components, right from request creation, review, approval, inventory management, issue or transfer to fulfil the requirement or ultimate disposal post shelf-life and information provided (including those generated using the information and documents provided by the User as part of the services, by self or though other registered apps.)</p>
            <p>Parties means collectively Serapis and Customer.</p>
<p>Party means individually Serapis and Customer.</p>
<p>BLUD APP / Website / PORTAL / Platform means the proprietary BLUD platform, infrastructure, products, and services, including, without limitation, the Services delivered and accessed via a variety of means as determined by Serapis, including, but not limited to API, Dashboard Information & Intelligence and MIS Reports.</p>
<p>Privacy Policy means the Serapis’s Privacy Policy available on the BLUD’s website / BLUD APP / PORTAL, as may be amended from time to time.</p>
<p>Service(s) means services (including but not limited to Dashboard, MIS, Reports, Status Alerts, information transfer, online payment services, etc. selected by Customer singly or combinedly), products, data and information provided by or through BLUD or authorised third-party service or data providers and/or the BLUD APP / Website / PORTAL which may be accessed by Customer via the BLUD APP / Website / PORTAL and the use of any BLUD website, whether logged in or not, including BLUD.app</p>
<p>Service Provider means any third party associated with the Serapis for providing any of the Services through BLUD APP / Website / PORTAL or any other means directly/indirectly through BLUD to the Users/Customers.</p>
<p>Service-Specific Attachment means the Service-specific terms and conditions that are incorporated by reference into a Service Order or contract.</p>
<p>Service Order means the order form, contract, or agreement, in a form provided by SERAPIS, between SERAPIS and Customer identifying Services ordered by or made available to Customer and signed or otherwise accepted by both Customer and SERAPIS.</p>
<p>Third-Party Services shall mean each and every Service provided by Service Provider to the Users/Customers via BLUD APP / WEBSITE / PORTAL or on reference or by any other mode directly, indirectly.</p>
      
<h6 className="primary-color">2.PERMITTED USES / USE OF BLUD APP / WEBSITE / PORTAL AND SERVICES
</h6>
<p>SERAPIS’s products and services are designed for bona fide use only and are not for any other use that is not expressly granted. Notwithstanding anything to the contrary in these Terms, any use for any business or commercial purpose that is competitive with SERAIS or its Affiliates or that may devalue SERAPIS’s or its Affiliates’ business value or commercial interests is expressly prohibited.</p>
<p>By registering, accessing, browsing, viewing, using, downloading, generating, receiving or transmitting any data, information or messages to or from the BLUD APP / WEBSITE / PORTAL, via the Services, and/or via BLUD website or service, Customer hereby accepts, without limitation or qualification, this Agreement as currently constituted and as may be updated from time to time in accordance with its terms.</p>
<p>You may avail the Services by contacting SERAPIS Customer services or applying online through BLUD APP / WEBSITE / PORTAL. Use of any BLUD Service or Third Party Service may be subject to the applicable Service Specific Attachment.</p>
<p>In its sole discretion, SERAPIS shall control the appearance, development and operation of the BLUD APP / PORTAL, the Services, and BLUD websites. SERAPIS may, in its sole discretion, set standards for the messaging performance between SERAPIS and Customer. Information and Data provided via the BLUD APP / WEBSITE / PORTAL or the Services may be changed and/or updated without prior notice.</p>
<p>You agree to regularly revisit and review the BLUD website for changes to the Policies and Terms & Conditions. In the event SERAPIS posts changes to these Terms or to the BLUD Web site / App and you continue use of the BLUD APP / WEBSITE / PORTAL and/or Services shall constitute Your agreement to such changes.</p>
<p>In the event of any conflict between contract documents you have with SERAPIS, the order of precedence shall be (i) any Service Order or contract for services; (ii) Service Specific Attachments, if any; and (iii) these Terms. Should a conflict exist between several Service Orders or several Service Specific Attachments the terms of the most recent Service Order or Service Specific Attachment, respectively, shall control.</p>
<h6 className="primary-color">3.USE OF THIRD-PARTY SERVICES THROUGH BLUD</h6>
<p>In addition to the these Terms/Agreement, You / User hereby agree, acknowledge and accept that the terms &amp; conditions of the Service Providers, as available on the website of the Service Provider of the particular Service(s) which You may access/avail, for availing/accessing Third Party Services shall also be read, confirmed and agreed.</p>
<h6 className="primary-color">4.ACCESS AND USES OF THE BLUD APP / WEBSITE / PORTAL
</h6>

<p>Customer agrees not to use the BLUD APP / WEBSITE / PORTAL, and/or the Services for any unlawful purpose or any purpose prohibited by these Terms.</p>
   <p className="mb-1">You agree not to:</p>   
      <ul  className="remove-list-style pl-3">
      <li>&#8213; use any device, software or technique to interfere with or attempt to interfere with the proper working of the BLUD APP / WEBSITE / PORTAL and/or the Services;</li>
<li>&#8213; post or transmit to the BLUD APP / WEBITE / PORTAL and/or the Services any unlawful, fraudulent, harassing, libellous, or obscene information of any kind;</li>
<li>&#8213; post or send to the BLUD APP / WEBSITE / PORTAL and/or the Services any information that contains a virus, bug, or other harmful item;</li>
<li>&#8213; post or transmit into or on the BLUD APP / WEBSITE / PORTAL and/or the Services any information in violation of another party’s contractual rights, privacy or data protection rights, or copyright or other intellectual property rights;</li>
<li>&#8213; use for any activities that are unlawful or against the laws of the land aimed towards destabilising the social order and justice or causing unrest or civic disorder or disrupting the communal harmony or peace or any form of terrorism or human trafficking or drug trade or for any activity considered to be bad in law in that country or by the UN Charter;</li>
<li>&#8213; take any action which imposes an unreasonable or disproportionately large load on the BLUD APP / WEBSITE / PORTAL and/or the Services infrastructure;</li>
<li>&#8213; use any device or technology to provide repeated automated attempts to access any portion of the BLUD APP / WEBSITE / PORTAL and/or the Services;</li>
<li>&#8213; use the BLUD APP / WEBSITE / PORTAL and/or the Services in any manner that could damage, disable, overburden, or impair the BLUD APP / WEBSITE / PORTAL and/or the Services or interfere with any other party’s use and enjoyment of the BLUD APP / WEBSITE / PORTAL and/or the Services;</li>
<li>&#8213; attempt to gain unauthorised access to any Service and/or the BLUD APP / WEBSITE / PORTAL, including, but not limited to, access through other accounts not legally registered to Customer, through any means;</li>
<li>&#8213; reengineer or reverse engineer the BLUD APP / PORTAL or components therefrom or its principles either granted under patent or not to cull out any derivative information for creating or augmenting or scaling up or diversification as a product or service to anyone including an existing BLUD user;</li>
<li>&#8213; pass User IDs or passwords to any third party without written consent from SERAPIS;</li>
<li>&#8213; use any robot, spider or other automated device, process or means to access the BLUD APP / WEBSITE / PORTAL and/or the Services or use any manual process to monitor or copy content from the BLUD APP / WEBSITE / PORTAL for any other unauthorised purpose without SERAPIS’s prior express written permission; and</li>
<li>&#8213; pass data received via the BLUD APP / WEBSITE / PORTAL and/or the Services to any third party outside Customer’s organisation, other than those on behalf of which Customer is legally acting, without the written consent of SERAPIS.</li>

      </ul>


      <p>Customer may not obtain or attempt to obtain any information through any means not intentionally provided to Customer by BLUD, including, without limitation, the practice known as screen scraping or any other forms of data harvesting.</p>
<p>In addition, Customer agrees not to copy, modify, adapt, reproduce, translate, distribute, transmit, reverse engineer, decompile, or disassemble any aspect of the BLUD APP / PORTAL and/or the Services (including any prices or service descriptions) unless specifically authorised by this Agreement or permitted by law despite this contractual prohibition. Actual or attempted unauthorised use of the BLUD APP / WEBSITE / PORTAL and/or the Services may result in criminal and/or civil prosecution.</p>
<p>You acknowledge that SERAPIS has the right, but no obligation, to monitor the BLUD APP / PORTAL and/or the Services and to disclose any information necessary to operate the BLUD APP / PORTAL and/or the Services, to protect SERAPIS, and BLUD customers and licensors, and to comply with legal obligations or governmental requests. SERAPIS reserves the right to refuse to post or to remove any information in the BLUD APP / WEBSITE / PORTAL and/or the Services, in whole or in part, for any reason.</p>
<p>You understand and agree the BLUD only facilitates the exchange of demand & supply information for Blood & Blood Components between the Patient, Hospital, Blood Bank and Donor. The onus of ascertaining the medical fitness of the donor and quality of acceptance of Blood & Blood Components rests with the receiving / accepting medical institution (Blood Bank and Hospital). You also understand and agree that BLUD does not have any medical expertise or experience or capability of a medical institution, to be held accountable or responsible for.</p>
<p>You agree to comply with all laws, statutes, ordinances, and regulations (including unfair competition, privacy and data protection, anti-discrimination or false advertising) regarding or relating to Your use of the BLUD APP / WEBSITE / PORTAL and/or the Services.</p>
<p>Users are prohibited from posting the Contents that (User Content):</p>

<ul  className="remove-list-style pl-3">
<li>&#8213;contains Viruses, Trojan horses, worms, time bombs, cancelbots, or other disabling devices or other harmful components intended to or that may damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or personal information;</li>
<li>&#8213;violates any applicable local, state, national or international law or otherwise advocates or encourages any illegal activity and violates rights of other users;</li>
<li>&#8213;is fraudulent, indecent, misleading or libellous, or defames, harasses, discriminates against, harms or threatens others;</li>
<li>&#8213;discusses illegal activities, with the intent to commit them;</li>
<li>&#8213;infringes or misappropriates patents, trademarks, trade secrets, right of publicity, or other intellectual property rights of third parties;</li>
<li>&#8213;is profane, pornographic, obscene, indecent or unlawful;</li>
<li>&#8213;related to partisan political activities, etc.</li>
</ul>

<p>SERAPIS monitors but does not guarantee control over the User Content posted through the Platform, including any messages, reviews or comments, and does not guarantee the accuracy, integrity or quality of such User Content as they all are posted by the users themselves. Under no circumstances will SERAPIS be liable in any way for any User Content, including any errors or omissions, or any loss or damage or defamation of any kind incurred as a result of your posting or use of any User Content.</p>
<p>You are responsible for complying with all the laws applicable to the User Content.</p>
<p>Users are prohibited from violating or attempting to violate the security of the Platform or any other associate Platform of SERAPIS. Violations of system or network security may result in civil or criminal liability. SERAPIS will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Platform or any activity being conducted on the Platform. You agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Platform other than the search engine and search agents available from BLUD on the Platform and other than generally available third-party trusted/secured web browsers.</p>
<p>The Platform may contain links to pages on other websites (Linked Sites), and those Linked Sites may contain Content or offer products and/or services for sale. SERAPIS does not author, edit, control, or monitor these Linked Sites. You acknowledge and agree that SERAPIS have no responsibility for the accuracy or availability of information provided by Linked Sites and SERAPIS do not control or endorse the sponsors of such Linked Sites or the content, products, advertising, or other materials presented on such Linked Sites.</p>
<p>SERAPIS shall not be held liable for any transactions conducted by you with third parties through the linked sites or for any liability arising from the representations or information provided on such linked sites.</p>
<p>SERAPIS makes no representations that the Platform operates or is legally permitted to operate in all geographic areas, countries, or that the Platform, or information, services or products offered through the Platform are appropriate or available for use in other locations.</p>
<p>In order to access and interact with the BLUD APP / PORTAL and/or the Services, Customer must be able to operate and maintain the necessary software and hardware, including, without limitation, Web browser software and appropriate communications infrastructure. Acquiring, installing, maintaining and operating any software and hardware needed to do so is solely Customer’s responsibility. SERAPIS is in no way responsible or liable for Customer’s access to the Internet, including, without limitation, any connection speed issues, bandwidth, browser compatibility, or latency-related problems that may affect Customer’s ability to access and use the BLUD APP / WEBSITE / PORTAL and/or the Services.</p>
<h6 className="primary-color">5.FEES AND PAYMENTS</h6>
<p>The fees for Services may include, without limitation, an onetime registration/set-up/implementation fee, an annual / periodic subscription or support fee, and/or a per-container or per-transaction fee. Any such fees are set forth in a Service Order.</p>
<p>Unless expressly set forth otherwise, Customer is liable for and will pay any and all applicable taxes relating to the Services.</p>
<p>SERAPIS will invoice Customer based on the terms of your Service Order for any particular Service. If no invoice terms are specified in such Service Order, SERAPIS will invoice as follows: (i) for monthly, quarterly, half-yearly annual, or other recurring or subscription charges: annually in advance; (ii) for one-time charges: immediately upon order; and (iii) for Services that are priced on the amount of Transacted Containers: monthly in arrears.</p>
<p>Customer shall pay all invoices when due as set forth in the applicable Service Order; provided that If no invoice terms are specified in such Service Order, invoices shall be due within 30 days of receipt.</p>
<p>Customer shall raise all invoice disputes within 30 days of the date of the invoice; otherwise Customer waives any right to dispute. In order for a dispute to be valid, Customer must include a detailed description of the disputed items, the reason for the dispute, and the requested resolution of the dispute. For any disputed invoice, Customer shall pay all undisputed amounts when due and then promptly cooperate with SERAPIS to investigate and resolve the disputed amount.</p>
<p>For any payment not received when due or within 30 days of Customer’s receipt of BLUD’s invoice, whichever is later, Customer’s balance due will accrue interest at 18% per annum, calculated monthly.</p>
<p>In addition to SERAPIS’s right to terminate this Agreement, in whole or in part, based on non-payment by Customer, SERAPIS may, after notice to Customer limit or suspend Customer’s access to any or all Services, in whole or in part, until Customer’s account is made current.</p>
<p>Unless expressly set forth otherwise, all fees charged or invoiced by SERAPIS are in the local currency, such as INDIAN RUPEES in INDIA, Ringgit in Malaysia, etc. and all payments due by Customer shall be paid in a manner acceptable to SERAPIS. International transactions are made in US Dollars or any internationally free trade currency as permitted by nations of both parties in common.</p>
<p>SERAPIS may modify pricing for any Services upon 90 days’ notice to Customer. During such notice period, Customer may terminate its use of the Services and any applicable Service Order for such affected Service but only for the affected Service.</p>
<h6 className="primary-color">6.ERRORS</h6>
<p>The Platform and/or Programmes may contain typographical errors or inaccuracies or omissions and may not be complete or current. BLUD, therefore, reserves the right to correct any errors, inaccuracies or omissions (including after an order has been submitted) and to change or update information at any time without prior notice. </p>
<p>ALL BLUD APP / WEBSITE / PORTAL DATA AND SERVICES ARE PROVIDED AS IS WITH NO GUARANTEES OF COMPLETENESS, ACCURACY, OR TIMELINESS OF RESULTS OBTAINED FROM THE USE OF BLUD APP / WEBSITE / PORTAL DATA OR THE SERVICES.</p>





<h6 className="primary-color">7.LINKED WEB SITES</h6>
<p>SERAPIS makes no warranties or representations whatsoever regarding any other Web sites Customer may access through the BLUD APP / WEBSITE / PORTAL and/or the Services. When accessing a non-BLUD Web site / Application (App), Customer understands that that such Web site / App/ App is independent from WEBSITE and that SERAPIS has no control over the content of that Web site. In addition, a link to a non-BLUD Web site / App does not mean that SERAPIS endorses or accepts any responsibility for the content or the use of such Web site / App unless specifically mentioned. It is up to the Customer to take precautions to ensure that whatever is selected for Customer’s use is in all ways suitable and free of viruses and other items of destructive nature.
      
</p>  

<h6 className="primary-color">8.DISCLAIMER OF WARRANTIES
</h6>

<p>SERAPIS DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED, OR STATUTORY, WITH REGARD TO THE BLUD APP / WEBSITE / PORTAL AND/OR THE SERVICES INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND USE OR FREEDOM FROM INFRINGEMENT OR THIRD PARTY INTELLECTUAL PROPERTY RIGHTS, OR ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. THE BLUD APP / WEBSITE / PORTAL AND/OR THE SERVICES MAY NOT BE CONTINUOUS, UNINTERRUPTED, ERROR-FREE, OR FREE OF ANY VIRUSES. SERAPIS FURTHER DISCLAIMS ANY WARRANTY OR REPRESENTATION REGARDING AVAILABILITY OF A SERVICE, SERVICE LEVELS OR PERFORMANCE. SERAPIS WILL NOT BE LIABLE FOR ANY LOSS OR INJURY ARISING OUT OF, IN WHOLE OR IN PART, SERAPIS’S CONDUCT IN COLLECTING, COMPILING, OR INTERPRETING INFORMATION.</p>
<p>CUSTOMER ASSUMES TOTAL RESPONSIBILITY AND RISK FOR THE USE OF THE BLUD APP / WEBSITE / PORTAL, SERVICES, BLUD APP / WEBSITE / PORTAL DATA, DOCUMENTS AND ANY OTHER DATA OR INFORMATION OFFERED BY OR THROUGH THE BLUD APP / WEBSITE / PORTAL AND/OR THE SERVICES.</p>
<p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY THE USER FROM SERAPIS OR THROUGH OR FROM THE SERVICES, SHALL CREATE ANY WARRANTY BY SERAPIS.</p>
<p>THE USER UNDERSTANDS AND AGREES THAT THE SERAPIS CONTENT AND ALL OTHER INFORMATION, DATA, OR OTHER MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH OR FROM THE PLATFORM OR PROGRAMMES IS OBTAINED AT THE USER’S OWN DISCRETION AND RISK, AND THAT THE USER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THE USER, THE USER’S COMPUTER SYSTEM, ELECTRONIC DEVICE OR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA.</p>
<p>IN THE EVENT YOU FIND THAT THE INFORMATION PROVIDED ON THE PLATFORM OR PROGRAMMES IS INCORRECT, OR IF YOU ARE THE OWNER OF ANY INFORMATION OR CONTENT AND WISH THAT SUCH INFORMATION OR CONTENT IS NOT DISPLAYED ON THE PLATFORM OR PROGRAMMES, KINDLY NOTIFY US AT THE ADDRESS PROVIDED AT THE BOTTOM OF THE PAGE.</p>



<h6 className="primary-color">9.LIMITATION OF LIABILITY</h6>

<p>NEITHER SERAPIS (INCLUDING EACH OF ITS RESPECTIVE EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, LICENSORS OR AFFILIATES) NOR ANY THIRD-PARTY SERVICE/DATA PROVIDER, whether in Tort, Contract, Strict Liability or otherwise, SHALL BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, EXEMPLARY, OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOST PROFITS, LOST REVENUES, OR BUSINESS INTERRUPTION) ARISING OUT OF, BASED ON, OR RESULTING FROM THIS AGREEMENT OR ANY DOCUMENTS, ANY BLUD APP / WEBSITE / PORTAL DATA, PERSONAL DATA AND OTHER DATA OR INFORMATION, ANY SOFTWARE OR ANY OF THE SERVICES PROVIDED THROUGH YOUR INTERACTION WITH THE BLUD APP / WEBSITE / PORTAL AND/OR SERVICES PROVIDED HEREUNDER, AS WELL AS ANY INFORMATION AND DOCUMENTS, ANY DATA OR MESSAGES GENERATED, RECEIVED, TRANSMITTED, DOWNLOADED OR OTHERWISE DISSEMINATED WHICH ARE RELATED TO OR STEM FROM CUSTOMER’S USE OF THE SERVICES AND/OR REGISTRATION AT THE BLUD APP / PORTAL. THE FOREGOING SHALL APPLY: (A) EVEN IF BLUD OR THIRD-PARTY SERVICE PROVIDER HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES; AND (B) REGARDLESS OF THE NEGLIGENCE OR OTHER FAULT OF EITHER PARTY, REGARDLESS OF WHETHER SUCH LIABILITY SOUNDS IN CONTRACT, NEGLIGENCE, TORT, OR ANY OTHER THEORY OF LIABILITY.</p>
<p>IN CONSIDERATION OF THE SERVICES PROVIDED BY SERAPIS, THE AGGREGATE CUMULATIVE LIABILITY OF SERAPIS OR THIRD-PARTY SERVICE OR DATA PROVIDERS TO SERAPIS, TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, ITS TERMINATION, OR ANY SERVICES, WILL NOT EXCEED THE LOWER OF (i) THE AMOUNT ACTUALLY PAID BY CUSTOMER TO SERAPIS DURING THE PRECEDING 3 MONTHS OR (ii) FIVE HUNDRED RUPEES.</p>
<p>YOU AGREE THAT ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES OR THIS AGREEMENT MUST BE ASSERTED WITHIN ONE YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE. YOU EXPRESSLY WAIVE ANY RIGHT YOU MAY OTHERWISE HAVE UNDER ANY STATUTE OR LAW FOR ANY CLAIMS NOT MADE WITHIN SUCH ONE YEAR PERIOD.</p>
<p>THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION REFLECT THE ALLOCATION OF RISK BETWEEN THE PARTIES. THE LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE AND SHALL INURE TO THE BENEFIT OF SERAPIS, INCLUDING ITS AFFILIATES, AND/OR ITS RESPECTIVE SUPPLIERS.</p>
<h6 className="primary-color">10.INDEMNIFICATION</h6>

<p>Customer will indemnify, hold harmless, and defend SERAPIS (including its Affiliates), and all of its (including of its Affiliates) current and former officers, directors, members, shareholders, agents, and employees (the Indemnified Parties), from any and all Claims.</p>
<p>Claim means any action, cause of action, suit, proceeding, claim, or demand of any third party (and all resulting judgments, bona fide settlements, penalties, fines, damages, losses, liabilities, costs, and expenses (including, without limitation, reasonable attorneys’ fees and costs), which arises out of: (a) Customer’s breach of this Agreement, or (b) CUSTOMR’S violation of any law or rights of any third party; or (c) information or Content that Customer/User post or otherwise make available on the Platform or through any Program, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights. SERAPIS will provide Customer with reasonable notice of any Claim. Customer will not settle any claim without BLUD’s prior written consent, which will not be unreasonably withheld.
</p>



<h6 className="primary-color">11.DATA PROTECTION</h6>
<p>This Agreement incorporates the SERAPIS Privacy and Security Policy (Privacy Policy) which are available at: <span className="primary-color">www.BLUD.app </span></p>
<p>When using the Service, Customer will have the option to provide certain personal or business contact information, including but not limited to, name, address, email address and telephone number (collectively, the ‘Personal Data’). </p>
<p>Customer will likely need to submit some Personal Data in order to submit transactions via the BLUD APP / WEBSITE / PORTAL. Customer agrees to: </p>
<ul className="remove-list-style pl-3">
<li>Provide true, accurate, current, and complete Personal Data as prompted by the Service processes</li>
<li>Maintain and promptly update the Personal Data to keep it accurate, current, and complete</li>
<li>Maintain the security and confidentiality of any usernames, passwords and any other security or access information used by the Customer to access the Service</li>
<li>Refrain from impersonating any person or entity or misrepresent Customer’s identity or affiliation with any person or entity, including using another person’s Personal Data</li>
<li>Immediately notify SERAPIS in writing if Customer becomes aware of any loss, theft or use by any other person or entity of any of its Personal Data in connection with the Service or any other breach of security that the Customer becomes aware of involving or relating to the Service </li>
<li>Only insert Personal Data into fields clearly designated to hold Personal Data. Examples of such fields include Name, Phone Number, Address, etc. SERAPIS will only monitor these fields as it relates to Personal Data rights and regulations.</li>

</ul>

<p>SERAPIS will not monitor fields for compliance with data protection laws that are not clearly intended to contain Personal Data (for example, mode of delivery or payment). SERAPIS will disclose fields not clearly intended to contain Personal Data to third parties, such as to a Carrier, without identifying them as containing Personal Data and if You insert Personal Data in such fields it may be further disclosed (including by publication or public display) to other third parties, such as in customs filings.</p>


<h6 className="primary-color">12.BLUD’S INTELLECTUAL PROPERTY RIGHTS
</h6>
<p>SERAPIS logos, trademarks and service marks that may appear on the Platform and in the Programme (SERAPIS Marks) are the property of SERAPIS and are protected under Indian laws. All other trademarks, service marks and logos used on the Platforms, Portals, with or without attribution, are the trademarks, service marks or logos of their respective owners. In addition, elements of the Platforms are protected by applicable Indian and international intellectual property laws and may not be copied, reproduced, downloaded or distributed in any way in whole or in part without the express written consent of SERAPIS.</p>
<p>As a condition of accessing the Platforms and/or using the Programmes, you agree not to:</p>
   <ol type="a">

        <li>reproduce, duplicate, copy, reengineer, reverse engineer, sell, resell or exploit for any commercial purpose or any portion of it thereof, other than as expressly allowed under these Terms; and</li>

        <li>use the SERAPIS Marks or the name, trademarks, service marks, or other materials of any Service Partner in connection with, or to transmit, any unsolicited communications or emails or for any other unauthorised purpose.
</li>


   </ol>
   <p>Any wilful effort to reproduce, duplicate, copy, reengineer, reverse engineer, sell, resell or exploit SERAPIS content or that of its service partner contrary to above clause will leave you open to legal action for piracy.</p>
   <h6 className="primary-color">13.
THIRD-PARTY INTELLECTUAL PROPERTY
</h6>
<p>SERAPIS respects third-party intellectual property rights and actively supports protection of all third-party intellectual property including copyrights and trademarks. It is our policy to expeditiously respond to clear notices of alleged infringement of Intellectual Property Rights. If we receive proper notification of infringement, our response to such notices will include removing or disabling access to material claimed to be the subject of infringing activity.</p>
<p>If you believe that your product or other work has been misrepresented or used in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide us a description of the product, work or other intellectual property that you claim has been misrepresented or infringed and a description of where the material that you claim is misrepresenting or infringing your product, work or other intellectual property is located on the Platform/Programme along with a statement by you that you have a good faith belief that the disputed use is not authorised by the owner of the product, work, copyright or intellectual property, or its agent, or applicable laws.</p>
<h6 className="primary-color">14.
End-User License Agreement

</h6>
<p>By agreeing to adhere to the clause 12 and 13 as aforesaid, the users agree to be abided by the End-Use Licenses Agreement (EULA) and agree to abide by the standard EULA terms and conditions including but not limited to those prescribed by Android, IOS, MacOS and Windows.</p>
<h6 className="primary-color">15.
ADDITIONAL TERMS

</h6>
<p>NIL</p>
<h6 className="primary-color">16.
Payment Terms and Refund Policy

</h6>


<p>Unless otherwise expressly agreed in writing by SERAPIS, full payment for participation in a Programme is required at the time of purchase of program/course.</p>
<p>It is the sole responsibility of the user enrolling into a Programme to check the accuracy of, and evaluate the suitability and relevance of, the Programme elected. The enrolment into a Programme is non-transferable.</p>
<p>To make payment for any Programme or to purchase any services or products offered by SERAPIS through the Platform, you must have internet access and a current valid accepted payment method as indicated during sign-up (Payment Method). SERAPIS does not store any of your credit card information or such other information restricted by the Reserve Bank of India (RBI) for processing payment and has partnered with payment gateways for the payment towards the services. By using a third-party payment provider, you agree to abide by the terms of such a payment provider. You agree that in case SERAPIS’s third-party payment provider stores any such information, SERAPIS will not be responsible for such storage, and it will be solely at your discretion to allow the third party to store such information. Any loss of such information or any loss incurred by you due to the usage of such information will be solely a loss incurred by you, and SERAPIS is in no way liable for any such losses and is neither responsible to reimburse / make good such losses in any manner whatsoever. You also agree to pay the applicable fees for the payments made through the Platform.
</p>

<p>No refund will be made once the payment (in part or whole) for a Programme has been made. We do not provide refunds for the lack of usage or dissatisfaction.</p>
<p>If you have subscribed for a free trial, the free trial period will last for the period specified during your sign-up. Free trials may not be combined with certain other offers. If you have subscribed to a particular paid service or product within the last six (6) months, or if your Payment Method, physical address or email address has been associated with an account for that paid service, you are not eligible to receive a free trial for that service or product. If you begin your subscription with a free trial, you may be ineligible for certain features of a product or service during your free trial period.</p>

<h6>Use of Programmes</h6>
<p>SERAPIS reserves the right to amend, modify, release, withdraw BLUD and its components anytime with prior notice of 15 days. SERAPIS also reserves the right to take unannounced temporary shutdown for facilitating a security check or as a preventive measure to hacking. You also understand that SERAPIS, at its sole discretion, may limit, suspend, or terminate your use of the Platform or Programmes and/or all SERAPIS provided services related to the Programmes, such as access to SERAPIS support services, evaluation services. You also understand that SERAPIS may modify or discontinue all services related to its Programmes at its sole discretion. You agree that SERAPIS shall not be liable to you or to any third party for any such modification, suspension or discontinuance. Nothing in these Terms shall be construed to obligate SERAPIS to maintain and support the Platform or Programmes or any part or portion thereof or any associated services. Any money collected for cancelled services will be refunded in proportion of the consumption or validity of the subscription.</p>
<h6>Limited License</h6>
<p>
The BLUD APP and service on the Platform are licensed, not sold. In consideration for your agreement to these Terms, SERAPIS grants you a personal, non-exclusive, non-transferable, revocable license to access and use the Platform and Program, solely in accordance with the Terms. You may download the BLUD APP and subscribe for your own personal, social and commercial use, provided you use it for legitimate and lawful purposes as per terms &amp; conditions refenced in this document. You may not copy, reengineer, reverse engineer, sell, resell, reproduce, publish, modify, transfer, retransmit, distribute, commercially exploit or create derivative works of BLUD or any of its component.
</p>
<p>You may not reverse-engineer, decompile, disassemble or otherwise access the source code for any software that may be used to operate BLUD. From time to time, BLUD may include software, code, instructions, or other such information in BLUD for use; any such information is provided on an as-is basis for use, analysis and are subject to the ‘Disclaimer’ and ‘Limitation of Liability’ sections below and other terms herein. SERAPIS and/or its affiliates and licensors reserve all rights not expressly granted herein to the BLUD APP, Website content, and BLUD Marks.</p>
<h6 className="primary-color">17.TERMINATION OF RIGHTS</h6>
<p>You agree that SERAPIS, in its sole discretion, may deactivate your account or otherwise terminate your use of the APP or subscription with or without reason, including, without limitation, if SERAPIS believes that you have:</p>
<ol type="a">
<li>breached the Terms;</li>
<li>infringed the intellectual property rights of a third party;</li>
<li>posted, uploaded or transmitted unauthorised Content on or through the APP / PORTAL; or</li>
<li>violated or acted inconsistently with the letter or spirit of these Terms or any other applicable code of conduct.</li>

</ol>
<p>You agree that any deactivation or termination of your access to the Platforms or Programmes may be effected without prior notice to you and that SERAPIS shall not be liable to you nor any third party for any termination of your account or enrolment into a Programme. You also acknowledge that SERAPIS may retain and store your information on SERAPIS’s systems notwithstanding any termination of your account or enrolment into the Programmes.</p>
<h6 className="primary-color">18.ASSIGNMENT
</h6>


<p>SERAPIS may freely transfer or assign any portion of its rights or delegate its obligations under these Terms or any Programme specific terms. You shall not transfer or assign, by operation of law or otherwise, any portion of your rights or delegate your obligations under these Terms or any subscription specific terms without the prior written consent of SERAPIS.</p>
<p>From time to time, SERAPIS may engage third parties to perform the Services, or any part thereof, provided that SERAPIS will be solely responsible to Customer for the performance of the Service by any such third party.</p>
<h6 className="primary-color">19.SEVERABILITY</h6>
<p>If any provision of the Terms or any Subscription-specific terms shared with you is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from the other provisions herein, and shall not affect the validity and enforceability of any remaining provisions.</p>
<h6 className="primary-color">20.WAIVER
</h6>

<p>The failure of SERAPIS to exercise or enforce any right or provision of the Terms or any subscription-specific terms shared with you, shall not constitute a waiver of such right or provision. If any provision of the Terms or any subscription-specific terms shared with you is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, to the extent that may lawfully be done, and the other provisions of these Terms or any subscription-specific terms shared with you, shall remain in full force and effect.</p>


<h6 className="primary-color">21.FORCE MAJEURE</h6>

<p>SERAPIS shall have no liability under these Terms or any subscription-specific terms shared with you, to the extent arising from any failure of SERAPIS to perform any of its obligations under these Terms or any subscription-specific terms shared with you, due to any fire, flood, earthquakes, other acts of God, war, civil unrest, terrorism, Internet failures, governmental act or court order, national emergency, pandemic, strikes or labour disputes or any other event not within SERAPIS’s reasonable control. SERAPIS shall not be responsible for damage or other problems caused by any unauthorised change to these Terms made by way of hacking or cracking this page/BLUD APP / website / PORTAL.</p>




<h6 className="primary-color">22.GOVERNING LAW AND JURISDICTION</h6>

<p>These Terms or any subscription-specific terms shared with you shall be governed by, construed and enforced in accordance with the laws in India, as these are applied to agreements entered into and to be performed entirely within India and without giving effect to any principles of conflict of laws. You agree that any legal lawsuit or other action brought by SERAPIS, you or any third party to enforce these Terms or any Programme-specific terms shared with you, or in connection with any matters related to the Application or website, shall be subject only to the jurisdiction of the courts of Navi Mumbai.</p>
<p>In case of any dispute, the parties involved shall make all reasonable efforts to resolve the dispute through amicable discussion within 30 days. If Parties are unsuccessful to resolve the dispute amicably, then such dispute or claim arising out of or in connection with the Terms or any subscription-specific terms shared with you, including any question regarding its existence, validity or termination, shall be referred to arbitration under the Arbitration and Conciliation Act, 1956, as amended (Act) before a sole arbitrator to be appointed by SERAPIS. The proceedings shall be conducted in English and the seat for arbitration shall be Mumbai.</p>
<h6 className="primary-color">22.NOTICES</h6>
<p>Any notice or communication that may be required to be given to SERAPIS under these Terms may be sent by writing or emailing to the following addresses by first class mail, overnight mail, courier, or registered email:</p>
<p>Serapis Knowledge Solutions Private Limited, Unit 16-A, H&G House, Sector-11, CBD Belapur, Navi Mumbai – 400614, Maharashtra, INDIA, Phone: +91-22-2756 1508/09, Email: Legal@BLUD.com, Blud@sbsact.com</p>
<small>Version 1.0; Last updated on May 06, 2021</small>
            <hr className="my-1"/>
            <p className="mb-1"> <small className="mb-1">Amendment History:</small></p>
           
            <table className="table">

                <tbody>
                    <tr>
                        <td>  <small>  06/May/2021</small>
</td>
                        <td>
                        <small> V1.0</small>

</td>
                        <td>  <small>Data Protection & Privacy Policy published</small></td>
                    </tr>
                    <tr>
                        <td>  <small>Date</small></td>
                        <td>  <small>Version</small></td>
                        <td>  <small>Release Notes</small></td>
                    </tr>

                </tbody>
            </table>
</div>

    )
}
