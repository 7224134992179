import React from 'react';
import AboutUsView from '../../views/aboutUs';
import Footer from '../../views/Footer/Footer';

export default function AboutUsContainer() {
    return (<>
        <div className="app-margin app-top-margin">
            <AboutUsView />
        </div>
        <Footer />

        </>
    )
}
