import React from "react";
import "./aboutUs.css";
import consulting from "../../assets/images/Consulting.png";
import sbs from "../../assets/images/sbs.png";
import bloodDonation from "../../assets/images/Blooddonation.png";
import serapis from "../../assets/images/serapis.png";
import digital_sol from "../../assets/images/digital_sol.png";
import blud from "../../assets/images/blud.png";
import PlaystoreLinks from "../PlaystoreLinks";
import MissonSaveLife from "../HomePage/MissonSaveLife";



export default function AboutUsView() {

  // const goToBlud
  return (
    <div className="app-sub-margin">
      <div className="row">
      <div className="main-header-title d-block d-lg-none col-12">About Us</div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-md-2 order-sm-2 order-lg-1 order-xl-1 order-2" id="first-col">
      <div className="main-header-title d-none d-lg-block">About Us</div>
    
          <p>
          We are a consulting organisation specialising in process improvements across<br/> wide domains. Most of us regularly donate blood as an altruitic act and encourage<br/> others to do so. The onset of pandemic changed the business landscape and<br/> 
          lifestyle alike. The devastating damage, though lesser known is the availability of<br/> blood and blood components. Even many regular donors refrained from routine<br/> donations fearing the contagion.

          </p>
          <p>
          The shortfall is estimated to be of the tune of about 3.5 million units making many<br/> patients vulnerable to the unavailability. This triggered us to study this problem<br/> and come up with a viable, sustainable solution - and thus BLUD app was created.


          </p>
          <p>
          With over 2 decades of assessment and consulting experience, we have mastered<br/> the skill of solution building apt to the problem on hand, in a sustainable manner.

          </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-md-1 order-sm-1 order-lg-2 order-xl-2 order-1 text-center">
          <img className="img-fluid about-us-img"  src={consulting} />
        </div>
      </div>

      <MissonSaveLife />
      {/* <div className="row align-items-center">
        <div className="col-12 main-header-title">Our Mission</div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <img src={bloodDonation} className="img-fluid about-us-img"/>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <p>

            Together with like-minded donors, participating blood banks and
            hospitals, we aim to bring an equilibrium in the supply - demand
            imbalance for whole blood.
          </p>
          <p>
            Establish a structured system for sustainable blood sourcing that is
            capable of ensuring blood availability.
          </p>
          <p>
            Provide a platform for mobilising large quantities of blood
            donations for surging demands during an emergency / disaster /
            calamity.
          </p>
          <p>
            Reduce the lead time from Request for Blood to ensuring its
            availability.
          </p>
          <p>
            Encourage youth (18-44 yrs) to come forward to donate blood and save
            lives.
          </p>
          <p>
            Influence a policy makers to recognise regular blood donors at work
            place for their altruitic act as recognition and motivation.
          </p>
          <p>
            Make India self sufficient in blood availability (sustainable
            sourcing) by 2022.
          </p>
          <p>
            Make the world self sufficient in blood availablity (sustainable
            sourcing) by 2023.
          </p>
          <p>Join the mission - Save Life!</p>
        </div>
</div> */}
<div className="row my-4">
        <div className="col-12 main-header-title my-3">  Our Firms</div>
        <div className="main-header-title mb-2 col-12 d-block d-md-none">Strategy and Business Solutions</div>

        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 order-md-1 order-sm-1 order-2 order-lg-1 order-xl-1">
          <div className="main-header-title mb-2 d-none d-md-block">Strategy and Business Solutions</div>
          <p><b>Strategy and Business Solutions (SBS)</b> is a Knowledge Organisation with international experience<br/> over three decades in diverse fields, form of direct occupation, consulting, training and / or<br/>
           assessments. Our experts bring in the wealth of information, knowledge and experience to assist &<br/>
            guide organisations in their pursuit for excellence.</p>
          <p>SBS also provides extensive range of assessment, consulting and training services, including bespoke<br/> training courses for the improving process performance, effectiveness, efficiency and reliability.</p>
          <p>When you choose <b>SBS</b> as your business partner, you are also choosing an international experience for<br/> excellence with the highest quality and integrity.</p>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 order-md-2 order-lg-2 order-xl-2 order-sm-2 order-1 text-center company-logo-main-div">
          <div  className="company-logo-div m-auto ">  <img  className="company-logo img-fluid" src={sbs} />
          <p><b className="primary-color">A</b>ssessment | <b className="primary-color">C</b>onsulting | <b className="primary-color">T</b>raining</p></div>
        

        </div>

</div>
<div className="row my-4">
<div className="main-header-title mb-2 d-block d-md-none col-12">Serapis Knowledge Solutions</div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  my-3 company-logo-main-div company-logo-div">
          <img className="img-fluid company-logo " src={serapis} />
        </div>
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 my-3">
          <div className="main-header-title mb-2 d-none d-md-block">Serapis Knowledge Solutions</div>
          <p> <b>Serapis Knowledge Solutions (Serapis)</b> is a multi-faceted organisation with global experience and <br/>
          clientele. At <b>Serapis</b>, we harness our knowledge, experience and success stories for the benefit of a<br/> larger audience through application, standardisation and improvement.</p>
          <p>As the result, we end up creating Digital Solutions (applications and tools) that reach out to select<br/> audience across the globe, while maintaining the customisation at ease. <b>Penzyl<sup>TM</sup> and BLUD<sup>TM</sup></b> are<br/> thus created to solve a common problem at large</p>
          <p>Strategy and Business Solutions is now a fully owned subsidiary of Serapis.</p>
        </div>
</div>
{/* <div className="row"> */}
        {/* <div className="col-12 main-header-title">
          Digital Solutions
         </div>
          <div className="col-12 bd-app-title">Penzyl<sup>TM</sup></div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-md-2 order-lg-1 order-xl-1">
          <p>Penzyl is an intelligent app that becomes a trusted assistant in your pocket. It facilitates users to manage Personal & Business tasks seamlessly without compromising on either. Enterprises no longer would reuquire to use a plethora of applications and worry about the integrity of their data. Benchmark security features protect information from being accessed or shared by unauthorised users. For Project Management Professionals, it is a boon that shows real time status of the tasks bringing in much desire transparency, visibility and control.</p>
          <button type="button" className="btn btn-primary">Discover</button>

          <PlaystoreLinks />
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-md-1 order-lg-2 order-xl-2">
          <img src={digital_sol} />

        </div>

        </div> */}
        <div className="row">
        <div className="col-12 main-header-title mb-4">
          Digital Solutions
         </div>
        <div className=" bd-app-title mb-2 col-12 d-block d-md-none  ">Blud<sup>TM</sup></div>

  
        <div className="col-xl-7 col-lg-7 col-md-7  col-sm-12 col-12 pr-5 order-md-1 order-lg-1 order-xl-1 order-sm-2 order-2">
        <div className=" bd-app-title mb-2 d-none d-md-block">Blud<sup>TM</sup></div>

          <p>Globally, atttaining self sufficiency in blood availability is a growing concern. There is no dearth of donors,<br/> but lacks a dynamic system in place. The App aims to automate this <b>‘Request to Donation cycle’</b> efficiently.<br/> By networking Hospitals, Blood Banks and Donors, <b>we aim to reduce the lead-time from request to blood<br/> availability</b> and also reduce the excess inventory carriage and wilful wastage (end of life Disposal).</p>
          <p>This App connects only with eligible donors, who <b>Volunteer to Donate Blood for Free</b> to save life, without<br/> any monetary expectations.</p>
          <p>Come, join the mission and save lives!</p>
          {/* <button type="button" className="btn btn-primary">Discover</button> */}
          <PlaystoreLinks />

        </div>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12  col-12 text-right company-logo-main-div order-md-2 order-lg-2 order-xl-2 order-sm-1 order-1">
          <img src={blud}  className="img-fluid blud-app"/>

        </div>
        </div>
   
    </div>
  );
}
