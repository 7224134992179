import React, {useState} from "react";
import {Tab, Tabs} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import {useParams} from "react-router";

export default function Questions() {
    const { type } = useParams();


    const [value, setValue] = useState(type ? type :  "Eligibility");
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const faqList = [
        {
            label: "Eligibility",
            questions: [
                {
                    question:
                        "Why is one not allowed to donate blood before attaining the age of 18 years ?",
                    ans: "Physiologically there is no harm if one satisfies other conditions for blood donation. 18 being the age of consent in our county, the reason is legal.",
                },
                {
                    question:
                        "Can one donate blood if one's body weight is less than 45 kg?",
                    ans: "No, you probably cannot. Most blood banks require the donor to be above 45 kg in weight. Blood banks these days take their donors’ health very seriously. There is no point in jeopardizing someone’s health when it is not so difficult to find an alternative healthy donor.",
                },
                {
                    question:
                        "Why blood is not collected from a person having a Haemoglobin content of less than 12.5 gm/l00ml?",
                    ans: "Blood with less Hb content than the prescribed (12.5gm/100ml) does not help the patient much in carrying oxygen to cells.",
                },
                {
                    question: "How to improve my Haemoglobin level?",
                    ans: "Hemoglobin level can be improved with simple lifestyle changes like eating a healthy and balanced diet and regular exercise. In some cases, iron supplements and medications might be needed.",
                },
                {
                    question: "Who can give blood?",
                    ans: "Anyone between 18 and 65 years of age and in normal health having a body weight of 45 kg or more and a haemoglobin content no less than 12.5 gms/hundred ml can be a donor.",
                },
                {
                    question: "Nobody has ever asked me to donate blood.",
                    // ans: "",
                },
                {
                    question: "I am too old!",
                    ans: "People donating blood for the first time cannot be over 60 years of age. First timers are deferred if they are over 60 years old, notwithstanding the other criteria that they may clear. However, for repeat donors, the upper age limit is 65 years",
                },
                {
                    question: "But I am underweight!",
                    ans: "Everyone wants to know can underweight donate blood without risk. The limitation for donating the blood is for the protection of the donor. The amount of blood in the body is related to height and weight. If the weight of a person is less than 45 kg, they cannot donate the blood because they are underweight.",
                },
                {
                    question: "Oh! But I am anaemic!",
                    ans: "If your hemoglobin is too low, you will asked to wait for donating. Iron is an essential mineral that is a part of hemoglobin which helps maintain your strength and energy. Your body needs iron to make new blood cells, replacing the ones lost through blood donations",
                },
                {
                    question:
                        "Is there any chance of contracting blood communicable disease by donating blood?",
                    ans: "Many viruses, bacteria and protozoa can be transmitted by transfusion and new agents that potentially can be transmitted through transfusion continue to emerge. WHO recommends that, at a minimum, screening of all blood donations should be mandatory for the following infections and using the following markers like HIV-1 and HIV-2, Hepatitis B, Hepatitis c etc.",
                },
                {
                    question: "Why should you not donate blood?",
                    ans: "A person with low blood count or low blood pressure should refrain from donating blood. In case you have an infection or you are suffering from common cough and cold,you should not donate blood,as it may transfer the infection to the recipient of the blood. Also,in case you have recently been treated with antibiotics,you should not donate blood. If you are underweight,you should not donate blood as the risk of nausea or fainting is high. In case you had a body piercing or a tattoo done,you should not donate blood as the risk of transferring hepatitis infection is high.",
                },
                {
                    question: "Can a diabetic donate blood?",
                    ans: "Diabetics on insulin are advised to not give blood,but diabetic patients without any complications from diabetes are allowed to donate blood. In case your blood glucose level is within range,you can go for blood donation. But,in case of any kidney related problems,eye or blood vessel related problem,you may not be allowed to donate blood.",
                },
                {
                    question: "Can I give blood if I smoke?",
                    ans: "People who smoke are allowed to donate blood,but it is advised to avoid smoking 12 hours before donating blood. Also,it is advised to not smoke at least 3 hours after donating blood. This is because smoking can lead to an increase in the blood pressure,which may harm you while donating blood. And smoking after donating blood may lead to fainting or dizziness.",
                },
            ],
        },
        {
            label: "Blood Donation Process", questions: [
                {
                    question: 'What diet should I follow if I am deciding to donate blood ?',
                    ans: 'Maintain a healthy iron level in your diet by eating iron rich foods, such as red meat, fish, poultry, beans, spinach, iron-fortified cereals,vagetable and raisins.'
                },
                {
                    question: 'Can I exercise before donating blood ?',
                    ans: "Avoid doing any vigorous exercise or heavy lifting the day of your donation – both before and after you've given blood. Keeping your body in a rested state is important to give it a chance to replenish the fluids lost during donation, which will help you avoid feeling dizzy or lightheaded and keep you well."
                }, {
                    question: 'I am feeling a bit nervous!',
                    ans: "Why? don't nurvous, go any blood camp and meet with a consultant where motivators ready to help you"
                }, {
                    question: 'Is there anything I need to carry with me while going for blood donation?',
                    ans: 'Please carry a Identity card in which your date of birth is mention.'
                }, {
                    question: 'What should I eat before going for a blood donation ?',
                    ans: 'You can eat normal diet as you taken in daily routine but it should be a healthy food.'
                },
                {
                    question:"What all should I know and take care prior to my Blood Donation?",
                    list:[
                        
"a) Eat well <4Hrs prior donation]",
"b) Sleep well",
"c) Drink enough water",
"d) Abstain from alcohol for 24 Hrs before and after donation",
"e) Avoid self medication",
"f) Report any medications you take",
"g) You would be required to complete a Donor Health Self Assessment Questionnaire",
"h) If you have any doubts / queries get them clarified by the qualified person at the Blood Centre",
                    ]
                }

            ]
        },
        {
            label: "How Safe it is?", questions: [
                {
                    question: 'What laboratory tests are performed in blood bank for each bag of collected blood ?',
                    list: [
                        "At each donation, the following mandatory tests are performed:",
                        "Hepatitis B – HBsAg",
                        "Human immunodeficiency virus – anti-HIV 1 and 2 and HIV NAT (nucleic acid testing)",
                        "Hepatitis C – anti-HCV and HCV NAT",
                        "Syphilis – syphilis antibodies.",
                        "Malarial antibodies",
                        "and some othe test Contact to a blood center"
                    ]
                },
                {
                    question: 'Why the above laboratory tests are not performed before donation ?',
                    ans: 'They are time consuming tests, and if performed in camp before donation, there will be excessive delay in disposal of donors, and the disgusted donors may leave the camps and refrain from donating blood for ever.'
                }, {
                    question: 'How long can blood be preserved in a blood bank ?',
                    ans: 'We can store blood for 42 days if we do not freeze it. Frozen blood can be stored ten years, but freezing blood is a poor way of storing it. Generally speaking, we store blood in the refrigerator, where we can store it for up to 42 days.Please view Donation Process link on SBTC home page'
                }, {
                    question: 'How much blood do you take in blood donation camp ?',
                    ans: "During a regular donation, you will give around 400 ml of whole blood. This is about eight per cent of the average adult's blood volume. The body replaces this volume within 24 to 48 hours, and replenishes red blood cells in 10 to 12 weeks."
                }, {
                    question: 'What is the liquid in the bag into which the blood is taken ?',
                    ans: 'It is called anti-coagulant and prevents the blood from clotting.'
                }, {
                    question: 'How often can one give blood ?',
                  list:[
                    "Most people can donate whole blood every 56 days. This is the most common type of blood donation.",
                    "Most people can donate red blood cells every 112 days.:",
                    "You can typically donate platelets once every 7 days, up to 24 times a year.:",
                    "You can typically donate plasma every 28 days, up to 13 times a year.:",
                    "If you give multiple types of blood donations, this will lower the number of donations that you can give per year."
                  ]
                }, {
                    question: 'Is blood donation very painful ?',
                    ans: "You may experience pain when the needle is inserted into your arm. You shouldn't feel any pain while the blood is being drawn, but you may experience an uncomfortable sensation at the site where the needle is inserted into your arm."
                }, {
                    question: 'What is AIDS? Is there any chance of contracting AIDS by donating blood ?',
                    ans: "HIV (human immunodeficiency virus) is a virus that attacks cells that help the body fight infection, making a person more vulnerable to other infections and diseases. It is spread by contact with certain bodily fluids of a person with HIV, most commonly during unprotected sex (sex without a condom or HIV medicine to prevent or treat HIV), or through sharing injection drug equipment.These are not proven cases of the virus being transmitted through blood transfusion."
                }, {
                    question: 'Does the blood group of a particular person change with time ?',
                    ans: "Almost always, an individual has the same blood group for whole life, but very rarely an individual's blood type changes through addition or suppression of an antigen in infection, malignancy, or autoimmune disease. Another more common cause of blood type change is a bone marrow transplant."
                }, {
                    question: 'what is rh factor in blood ?',
                    ans: "Almost always, an individual Rhesus (Rh) factor is an inherited protein found on the surface of red blood cells. If your blood has the protein, you're Rh positive. If your blood lacks the protein, you're Rh negative. Rh positive is the most common blood type."
                }

            ]
        },


        {
            label: "Pre Donation", questions: [
                {
                    question: 'Can I donate if I recently had a Rabies vaccination?',
                    ans: 'Past one year - not been treated for Rabies or received Hepatitis B immune globulin.'
                },
                {
                    question: 'What about other Vaccinations?',
                    ans: 'Past one month - had any immunisations, including second dose of COVID-19 vaccines.'
                }, {
                    question: 'Can I donate if I recently had a Tattoo or Body Piercing?',
                    ans: 'Past six months - not had a tattoo, ear or skin piercing or acupuncture, not received blood or blood products, no serious illness or major surgery, no contact with a person with hepatitis or yellow jaundice.'
                }, {
                    question: 'Can I donate if Malaria is in the air?',
                    ans: 'Past three months - not donated blood or been treated for Malaria.'
                }, {
                    question: 'Are there any restrictions of medications?',
                    // ans: 'Past 48 hours - taken any antibiotics or any other medications (Allopathic or Ayurveda or Sidha or Homeo)',
                    list: [
                        "Past 48 hours - not had taken any antibiotics or any other medications (Allopathic or Ayurveda or Sidha or Homeo)",
                        "Past 72 hours - had dental work or taken Aspirin"
                    ]
                }, {
                    question: 'What if I take alcohol moderately every day?',
                    ans: 'You shouldn’t have consumed alcoholic beverages in the past 24 hours'
                }, {
                    question: 'What if I had a surgery, though not related to heart?',
                    ans: 'If you have had any surgery, you can’t donate blood for 1 year. However, there are some exceptions, that the Doctor at the Blood Bank would clarify.'
                }, {
                    question: 'What about seasonal cold or flu?',
                    ans: 'At present, you should not be suffering from cough, influenza or sore throat, common cold to avoid transmission.'
                }, {
                    question: 'Any restrictions for women donors?',
                    ans: 'Women can’t donate blood during pregnancy or breast feeding her child or under her menstrual cycle.'
                }, {
                    question: 'What about lifestyle diseases like BP, Cholesterol, Diabetes, etc.?',
                    ans: 'One should not suffering from chest pain, heart disease or high BP, cancer, blood clotting problem or blood disease, unexplained fever, weight loss, fatigue, night sweats, enlarged lymph nodes in armpits, neck or groin, white patches in the mouth etc. For Diabetes, it must be under control without insulin.'
                }, {
                    question: 'Any other restrictions to donate blood?',
                    ans: 'Ever had TB, bronchial asthma or allergic disorder, liver disease, kidney disease, fits or fainting, blue or purple spots on the skin or mucous membranes, received human pituitary - growth hormones etc.'
                },

            ]
        },


        {
            label: "Post Donation", questions: [
                {
                    question: 'General Precautions:',
                    list: [
                        " Keep the strip bandage on for the next several hours; to avoid a skin rash, clean the area around the bandage with soap and water.",
                        " Don’t do any heavy lifting or vigorous exercise for the rest of the day.",
                        " If the needle site starts to bleed, apply pressure and raise your arm straight up for 5-10 minutes or until bleeding stops.",
                        " Call the Blood Centre to report any additional health information that you forgot to tell them, if you have any problems or if you needed medical care after giving blood.",
                        " If you experience dizziness or lightheadedness, stop what you’re doing and sit down or lie down until you feel better; avoid performing any activity where fainting may lead to injury for at least 24 hours.",
                        " Keep eating iron-rich foods.",
                        " If you donate frequently, be sure to take multivitamins with iron to ensure you continue to replenish your iron stores before your next donation.",
                        " Tell other about your Blood donation and inspire them to donate."
                    ]
                }
            ]
        },
        {
            label: "More", questions: [
                {
                    question: 'Side effects of blood donation',
                    ans: 'While most donors do not experience any side effects,some people are known to show some symptoms after blood donation. The side effects of donating blood include nausea and dizziness and fainting in some cases. You may develop a raised bump or experience continued bleeding and bruising at the needle site too. Some people might experience pain and physical weakness after donating blood.'
                },
                {
                    question: 'Does the blood group of a particular person change with time?',
                    ans: 'Usually, you will have the same blood type all of your life. However, in some cases, the blood types have changed. This has been due to unusual circumstances, such as having a bone marrow transplant or getting certain types of cancers or infections. Not all of the changes in blood type are permanent.'
                }, {
                    question: 'What is Rh factor?   ',
                    ans: "Rhesus (Rh) factor is an inherited protein found on the surface of red blood cells. If your blood has the protein, you're Rh positive. If your blood lacks the protein, you're Rh negative."
                }, {
                    question: 'How much blood should you donate?',
                    ans: 'Normally,one unit of blood,that is 350 ml,is collected from a healthy individual when the whole blood is collected. An adult normally has 5 – 6 litres of blood and can safely donate 350 ml once every 3 months.'
                }, {
                    question: 'Is it healthy to give blood?',
                    ans: 'Blood donationis a healthy process. The human body can replenish the blood donated in 4 to 8 weeks,while the blood plasma gets replenished within 48 hours. Donating blood helps in clearing the harmful iron deposits in the body,which helps in the prevention of cardiovascular diseases. With blood replenishing by itself,the pancreas and liver can stay healthy too.'
                }, {
                    question: 'Does giving blood lower your immune system?',
                    ans: 'Giving blood reduces the red blood cells and may lead to a temporary drop in certain immune cells such as circulating serum protein and antibodies. But if your body needs these antibodies,it will produce them extremely quickly and the blood will return to a normal state within a few weeks.'
                }, {
                    question: 'Does donating blood clean your system?',
                    ans: 'Donating blood helps in reducing the harmful iron deposits in the body and prevents hemochromatosis. Regularly donating blood helps in keeping a check on the body’s iron stores,thereby reducing the risk of heart attack. After blood donation,the body replaces the blood volume within 48 hours,which helps it to become healthier and work more efficiently.'
                }, {
                    question: 'Do blood donors live longer?',
                    ans: 'Blood donors live a healthy life as they get regularly checked for any health problems and their risk of cardiovascular diseases and cancer decreases too. Your liver and pancreas stay healthier,which in turn means that your chances of living longer become higher.'
                }, {
                    question: 'How do you not pass out when giving blood?',
                    ans: 'Before blood donation,drink lots of fluids and have a healthy snack. The meals before donating blood should be normal and regular. Avoid hot shower and overheated or stuffy environments. Avoid high or vigorous exercise and standing for long periods. Have a good night’s rest before the day of donation. In case you feel dizzy,lie down so that the blood flows to the brain,which will help in preventing fainting. Sitting down with head lowered forward between the knees can also help in better blood circulation to the brain. It is advisable to increase the intake of iron-rich foods after blood donation for quicker recovery.'
                }, {
                    question: 'How often can I donate blood?',
                    ans: 'Since the blood completely replenishes itself within 4 – 8 weeks,you can donate blood every 56 days. You are allowed to donate plasma every 28 days,up to 13 times a year,and platelets can be donated once every 7 days,up to 24 times a year. Red blood cells can only be donated 3 times a year,with a gap of 112 days in between.'
                }, {
                    question: 'It is dangerous to take part in sports or other physical activities after donating blood.',
                    ans: 'Your body can safely function to perform your day-to-day activities after an hour of donating blood. You can also safely take part in physical sports and resume a normal schedule the day after you donate blood.'
                }, {
                    question: "I can't give blood because I'm on medication.",
                    ans: 'It is safe to donate blood on almost all types of medication, as long as you are healthy and when the condition you are being treated for is under control.'
                },

                {
                    question: 'It takes up to six months to replenish the blood that you donate.',
                    ans: 'The recommended period between donations is three to four months. Different components take different lengths of time to replenish. Your blood volume is usually restored within 10 minutes, plasma within 24 hours, platelets within 72 hours and red blood cells in two to four weeks, while iron requires 12 weeks in men and 16 weeks in women.'
                }, {
                    question: 'I should not donate blood frequently; it will make my body weak.',
                    ans: "A healthy person can donate blood up to four times a year without any adverse effects on one's body. Make sure to maintain a minimum three-month gap between each blood donation."
                }, {
                    question: "It's okay to smoke or drink a day before, blood donation.",
                    ans: 'It is advisable to refrain from smoking at least two hours before you donate blood. It is also not advisable to consume alcohol at least 24 hours before donating blood.'
                }, {
                    question: "I can't give blood because I have high blood pressure.",
                    ans: 'As long as your blood pressure levels are between 180 systolic (top number) and 100 diastolic (bottom number) at the time of blood donation; it is safe for you to donate blood. To that effect, blood pressure medication does not disqualify you from donating blood.'
                }
            ]
        },
    ];


    return (
        <div>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
            >
                {faqList.map((item, i) => {
                    return <Tab key={i} value={item.label} label={item.label} />;
                })}
            </Tabs>
     

            {faqList[
                faqList.findIndex((data) => data.label === value)
            ]?.questions?.map((item, index) => {
                return (
                    <Accordion key={index}
                        square
                        expanded={ value==="Post Donation" ? true:expanded === index }
                        onChange={handleChange(index)}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={(expanded === index ) ? "-" :  value!=="Post Donation" ? '+' :'' }
                        >
                            <Typography className="faq-question">{item.question}</Typography>
                        </AccordionSummary>
                        
                        {item.ans ?
                            <AccordionDetails>
                                <Typography className="faq-ans">{item.ans}</Typography>

                            </AccordionDetails> : ''}
                            <ul>
                           

                        {item.list ? item.list.map((item, i) => {
                            return <li key={i} className="my-1 ml-4">{item} <br /></li>
                        }) : ''}
                        </ul>
                    </Accordion>
                );
            })}
        </div>
    );
}




