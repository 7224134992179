import React from 'react';
import bloodOrange from '../../assets/images/blood_orange.png';
import bloodRed from '../../assets/images/blood_red.png';
import bloodYellow from '../../assets/images/blood_yellow.png';


export default function EligibilityCrieteria() {

    const criteriaList = [
        {
            title: 'Whole Blood Donation',
            img: bloodRed,
            list: ['Must be fit & healthy', 'Age: 18 - 65 years', 'Weight: 50 kgs and above',
                'Pluse rate: between 50-100', 'Hemoglobin level: minimum 12.5g/dL', 'Donation frequency: 3 months for male and 4 months for female']
        },
        {
            title: 'Plasma Donation',
            img: bloodYellow,
            list: ['Must be fit & healthy', 'Age: 18 - 65 years', 'Weight: 50 kgs and above',
                'Pluse rate: between 50-100', 'Hemoglobin level: minimum 12.5g/dL', 'Donation frequency: 28 days']
        }, {
            title: 'Platelet Donation',
            img: bloodOrange,
            list: ['Must be fit & healthy', 'Age: 18 - 65 years', 'Weight: 50 kgs and above',
                'Pluse rate: between 50-100', 'Hemoglobin level: minimum 12.5g/dL', 'Donation frequency: 7 days']
        }
    ];
    return (
        <div className="app-margin app-sub-margin">

            <div className="main-header-title">Eligibility Criteria</div>
            <p className="mb-5 bd-donations-bu-title">To ensure the safety of both patients and donors, these are some of the requirements donors must meet to be eligible to donate blood based on their donation type.</p>
            <div className="row text-center  justify-content-center">
                {criteriaList.map((item, i) => {

                    return <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3" key={i}>
                        <div className="bd_eligibility-div m-auto">

                            <img src={item.img} className="bd_drop" />
                            <div className="sub-title">{item.title}</div>
                            <ul>
                                {item.list.map((listItem, j) => {
                                    return <li key={j}>{listItem}</li>
                                })}
                            </ul>

                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}
