import React from "react";
import WhoImg from "../../assets/images/WHO.png";

export default function Mobilisation() {
  return (
    <div className="row app-margin">
        <div className="main-header-title  col-12 px-0 mb-3 d-block d-xl-none">Emergency mobilisation - Blood Donation Drive</div>

      <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12 col-12 p-0 text-center who-img-div">
        <img className="img-fluid who-img " src={WhoImg} />
      </div>
      <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12">
        <div className="main-header-title  d-none d-xl-block">Emergency mobilisation -<br/> Blood Donation Drive</div>
        <p>
          This App not only connects eligible voluntary donors with the blood
          banks to fulfil the needs, but also facilitates instant mobilisation
          for huge quantity of blood donation. This is very much required during
          an emergency situation like natural disasters, industrial accidents,
          fire, road accidents, etc.
        </p>
        <p>
          Concerned Hospital or Blood Bank can raise the request for 100’s of
          units of blood to be collected from mass donation within the shortest
          span of time and save as many lives possible.
        </p>
        <p>
          The emergency donation request is sent to all eligible donors
          irrespective of their blood group to donate blood during the crisis
          and thus the required quantity of blood of various types can be
          collected on the spot.
        </p>
        <p>
          This makes a perfect opportunity for first time donors to realise the
          worth of their blood!
        </p>
      </div>
    </div>
  );
}


