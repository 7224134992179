import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
const mapStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "18px"
};

class MapViewClass extends Component {
  render() {
    return (
      <>
         <iframe className="map" width="100%" height="500" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.0434951809225!2d73.04104671442379!3d19.017804958742268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c252564ac983%3A0x25d67d5a988fa49!2sStrategy+and+Business+Solutions!5e0!3m2!1sen!2sin!4v1452062983193" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe><br />

      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC1Gd7UqPWfyHWB-jDkJ6oHjS1CY2ceyyo",
})(MapViewClass);
