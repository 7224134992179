import {TRIGGER_CONTACT_US,TRIGGER_CONTACT_US_SUCCESS,TRIGGER_CONTACT_US_FAILED} from "../actions/actionTypes";

const initialState = {
    contactUsObj: {
      loading: false,
      contactUsObject: [],
      error: null,
    }
  }
  
  const contactUsReducer = function (state = initialState, action) {
   switch(action.type) {
     case TRIGGER_CONTACT_US:
       return {
         ...state,
         contactUsObj: {
           loading: true,
           contactUsObj: [],
           error: null,
         },
       };
     case TRIGGER_CONTACT_US_SUCCESS:
       let contactUsObj = action?.payload?.data ?? [];
       return {
         ...state,
         contactUsObj: {
           loading: false,
           contactUsObj,
           error: null,
         },
       };
  
     case TRIGGER_CONTACT_US_FAILED:
       return {
         ...state,
         contactUsObj: {
           loading: false,
           bloodComponentList: [],
           error: action.payload?.data?.message,
         },
       };
  
     default:
       return state;
   }
  }
  
  export default contactUsReducer;
  