import React from "react";
import mission from "../../assets/images/mission.png";

export default function MissonSaveLife() {
  return (
    <div className="row">
        <div  className="col-12 main-header-title mb-3">Our Mission</div>
       <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 who-img-div">
        <img className="img-fluid who-img" src={mission} />
      </div>
      <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12  ">
        <p>
        For 18 million units of demand in India, there is an availability of about 14.5 mil units of Blood. Only a<br/> meagre ~4% donors repeat more than once a year. This leaves us short of about 20%, that is 1<br/> in every 5 patient is likely to face unavailability of Blood. 
        </p>
        <p>
        Let’s lend a hand and make India self sufficient in Blood availability. For this, we need:
        </p>
        <ul>
          <li>40 million donors of the age group 18-44 years</li>
          <li>
            Low Haemoglobin level is the primary cause for deferment of donors.
    
          </li>
          <li>
            Every donor donates blood at least once every year and inducts 1
            more donor for this cause
          </li>
          <li>
            Every Blood Bank in the country participates in this drive and gets
            integrated for stock<br/> visibility and effective utilisation of
            available blood
          </li>
          <li>
            Every transfusion centre (Hospital), posts their requirement and
            requests through this portal<br/> that connects them with the nearest
            availability (blood) to save time and life
          </li>
          <li>
            Enterprise participation by granting the day off for their employees
            donating blood and<br/> can also form a criteria for recruitment &
            placement
          </li>
          <li>
            Regular Blood donors can withdraw 1 unit of blood against 4
            donations without<br/> replacement
          </li>
        </ul>
      </div>
     
    </div>
  );
}


