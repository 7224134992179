import React, { useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

export default function Captcha({doSubmit,captchaMatch,isSubmit}) {

  useEffect(() => {
    loadCaptchaEnginge(6);
  },[]);



  return (
    <div className="form-group bd-captcha">
      <LoadCanvasTemplate reloadText="Reload Captcha" />
      <div className=" mt-3">
          <div>
            <input
              className={"form-control " + (!captchaMatch && isSubmit ? 'error-border' :'form-control') }
              placeholder="Enter Captcha Value"
              id="user_captcha_input"
              name="user_captcha_input"
              type="text" 
            ></input>
            {/* {!captchaMatch && isSubmit ? <p className="error">Please validate captcha</p>:''} */}
            {/* {captchaMatch  ? <p className="primary-color">Captcha validated successfully!</p>:''} */}

        </div>

        {/* <div className="col-3">
          <div>
            <button className="btn btn-primary"  onClick={doSubmit}>
              Submit
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}
