import {TRIGGER_BLOOD_REQUEST,TRIGGER_BLOOD_REQUEST_SUCCESS,TRIGGER_BLOOD_REQUEST_FAILED} from "../actions/actionTypes";

const initialState = {
    bloodRequestObj: {
      loading: false,
      bloodRequestObject: [],
      error: null,
    }
  }
  
  const requestBloodReducer = function (state = initialState, action) {
   switch(action.type) {
     case TRIGGER_BLOOD_REQUEST:
       return {
         ...state,
         bloodRequestObj: {
           loading: true,
           bloodRequestObj: [],
           error: null,
         },
       };
     case TRIGGER_BLOOD_REQUEST_SUCCESS:
       let bloodRequestObj = action?.payload?.data?.content ?? [];
       return {
         ...state,
         bloodRequestObj: {
           loading: false,
           bloodRequestObj,
           error: null,
         },
       };
  
     case TRIGGER_BLOOD_REQUEST_FAILED:
       return {
         ...state,
         bloodRequestObj: {
           loading: false,
           bloodComponentList: [],
           error: action.payload?.data?.message,
         },
       };
  
     default:
       return state;
   }
  }
  
  export default requestBloodReducer;
  