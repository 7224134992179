import React from 'react'
import BloodDonationProcess from '../../views/bloodDonationProcess/BloodDonationProcess'
import Footer from '../../views/Footer/Footer'
import BloodAvailabilityNear from '../../views/HomePage/BloodAvailabilityNear'
import Counts from '../../views/HomePage/Counts'
import FillGap from '../../views/HomePage/FillGap'
import GetTheApp from '../../views/HomePage/GetTheApp'
import MissonSaveLife from '../../views/HomePage/MissonSaveLife'
import Mobilisation from '../../views/HomePage/Mobilisation'
import Who from '../../views/HomePage/Who'
import SliderView from '../../views/Slider'

export default function HomePageContainer() {
    return (
        <div className="bd_homepage">
            <SliderView/>
            <Counts />
            {/* <Who /> */}
            <BloodAvailabilityNear />

            <BloodDonationProcess />
            <FillGap />
            {/* <MissonSaveLife /> */}
            {/* <Mobilisation/> */}
            <GetTheApp />
            <Footer />
        </div>
    )
}
