import React, {useEffect, useState} from "react";
import Captcha from "./Captcha";
import ContactUs from "./ContactUs";
import Map from "./Map";
import MapViewClass from "./Map";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from "react-redux";
export default function ContactUsView({contactObj, onSubmit, onChangeText, doSubmit, showPopUp, isSubmit, resetForm, captchaMatch, mobilePattern, emailPattern}) {

  const { contactUsObj } = useSelector((state) => state.contactUsReducer);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    
    if(contactUsObj?.contactUsObj?.code===200 ){
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [contactUsObj]);  
  return (
    <div className="app-sub-margin bd-contact-us">
      <div className="main-header-title">Contact us</div>
      <p className="contact-us-para">Have a query, concern, feedback or want to suggest a feature, write to us.
</p>
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 ">
          <ContactUs contactObj={contactObj} mobilePattern={mobilePattern} emailPattern={emailPattern} captchaMatch={captchaMatch} isSubmit={isSubmit} onChangeText={onChangeText} showPopUp={showPopUp} />
          <div className="row col-12">

            <div className="col-12 px-0">
              <Captcha doSubmit={doSubmit} captchaMatch={captchaMatch} isSubmit={isSubmit} />
            </div>
            <div className="col-12 d-flex justify-space-evenly mt-3">
              <button className="btn btn-outline-danger clear-btn" type="button" onClick={resetForm}>
                Clear
              </button>

              <button className="btn btn-primary d-flex align-items-center" type="submit" onClick={onSubmit}>
                {isSubmit && contactUsObj.loading  ?  <CircularProgress className="progress-spinner mr-2" /> : ''}
               
              Send Message
              </button>
            </div>

       
            <p className="my-2 primary-color">{alert ? "Message sent and you will be contacted / informed about it shortly." : ''}</p>

            

          </div>


        </div>

        <div className="col-lg-6 col-md-12  col-sm-12 col-xl-6 offset-xl-1 map col-12">

          <MapViewClass />
        </div>
      </div>


    </div>
  );
}
