import {combineReducers} from "redux";
import contactUsReducer from "./contactUsReducer";
import requestBloodReducer from "./requestBloodReducer";

const rootReducer = combineReducers({
    requestBloodReducer:requestBloodReducer,
    contactUsReducer:contactUsReducer
});

export default rootReducer;
