export const TRIGGER_BLOOD_REQUEST = "TRIGGER_BLOOD_REQUEST";
export const TRIGGER_BLOOD_REQUEST_SUCCESS = "TRIGGER_BLOOD_REQUEST_SUCCESS";
export const TRIGGER_BLOOD_REQUEST_FAILED = "TRIGGER_BLOOD_REQUEST_FAILED";

export const TRIGGER_REGISTER = "TRIGGER_REGISTER";
export const TRIGGER_REGISTER_SUCCESS = "TRIGGER_REGISTER_SUCCESS";
export const TRIGGER_REGISTER_FAILED = "TRIGGER_REGISTER_FAILED";


export const TRIGGER_CONTACT_US = "TRIGGER_CONTACT_US";
export const TRIGGER_CONTACT_US_SUCCESS = "TRIGGER_CONTACT_US_SUCCESS";
export const TRIGGER_CONTACT_US_FAILED = "TRIGGER_CONTACT_US_FAILED";