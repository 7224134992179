import React from "react";
import Slider1 from "../../assets/images/boy.png";
import PlaystoreLinks from "../PlaystoreLinks";

export default function Slider1View() {
  return (

   
   
 <div className="slider1-grid">
 <div  className="position-relative h-100">
   <div className="left-content-div">
   <div className="main-heading">Your search for <span className="primary-color"> Blood</span> </div>
   <div className="main-heading "> Ends here</div>
   <p>We understand the plight of the families of Thalassaemia /<br/> Hemophilia patient. No more worries. Just complete your<br/> medical profile in the App. Donors will be lined up on schedule.</p>
 
   </div>
 <div className="playstore-div">
   <PlaystoreLinks downloadApp={false}/>

  </div>
 </div>





 <div className="text-center pl-0 slider-slogan">

     <img className="slider2-img slider1-img img-fluid" src={Slider1} />
   </div>
 </div>

  );
}
