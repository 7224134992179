import React from 'react'
import Slider1 from '../../assets/images/blood-drop.png';
import PlaystoreLinks from '../PlaystoreLinks';

export default function Slider5View() {
    return ( 

<div className="slider2-grid">
<div className="position-relative h-100">
<div className="left-content-div">
      <div className="main-heading"> Donate Blood</div>
      <div className="main-heading main-sub-heading"> Save Life</div>
      <p>An appeal to those 18-44 years of age!<br/>
Let’s make India self sufficient in blood availability by 2022<br/>
and enable the world to attain self sufficiency by 2023</p>
</div>
<div className="playstore-div">
   <PlaystoreLinks downloadApp={false}/>

  </div>
    </div>
    <div className="slider2-img-div text-center">
      <img className=" img-fluid slider5-img" src={Slider1} />

    </div>


      <div className="slider-slogan">
      <div className="slider-heading">
          #BE A HERO </div>
          <div className="slider-subheading"> YOU DON’T HAVE TO<br/> BE A DOCTOR</div>
          <div className="slider-subheading  slider-subheading1">TO SAVE A LIFE</div>
      </div>

    

  </div>
    )
}




 
