import React from 'react';
import Bank from '../../assets/images/Bank.png';
import Donors from '../../assets/images/Donors.png';
import Donation from '../../assets/images/Donation.png';


export default function Counts() {
    const countList=[{
        label:"Blood Banks",
        img:Bank,
        count:"3023"
    },{
        label:"Donors",
        img:Donors,
        count:"15.35 Lakh"
    },{
        label:"voluntary Donations",
        img:Donation,
        count:"78%"
    }];
    return (
        <div className="app-padding"> 
         <div className="bd_counts ">
            {countList.map((item,i)=>{
                return    <div className="d-flex align-items-center my-3" key={i}>
                    <img src={item.img} />
                    <div>
                        <div className="bd_counts_value">{item.count} </div>
                        <div className="bd_counts_label">{item.label} </div>

                        </div>

                </div>
            })}
         
            
        </div></div>
       
    )
}
