import React, {useEffect, useState} from "react";
import drop from "../../assets/images/drop.png";
import arrow from "../../assets/images/arrow.png";
import qr from "../../assets/images/qr.png";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DialogContent} from "@material-ui/core";
export default function BloodAvailabilityNear() {
  const [formToggle, setFormToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [msg, setMsg] = useState('');

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  const toggleForm = (msg) => {
    setMsg(msg)
    if (formToggle) {
      setFormToggle(false)
    }
    else {
      setFormToggle(true)

    }

  }
  const count = [
    {
      bloodGroup: "A+",
      count: '3,023',
    },
    {
      bloodGroup: "A-",
      count: 812,
    },
    {
      bloodGroup: "AB+",
      count: 906,
    },
    {
      bloodGroup: "B+",
      count: "5,237",
    },
    {
      bloodGroup: "B-",
      count: 491,
    },
    {
      bloodGroup: "AB-",
      count: 64,
    },
    {
      bloodGroup: "O+",
      count: '19,861',
    },
    {
      bloodGroup: "O-",
      count: 440,
    },
    {
      bloodGroup: "HH",
      count: 24,
    },
  ];
  return (<>
    <div className="app-margin app-sub-margin">
      <div className="main-header-title">
        Blood availability near you
      </div>

      <div className="blud_nearby ">
        {count.map((item, i) => {
          return (
            <div key={i}>
              <div className="row align-items-center" key={i}>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1  px-0">
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 text-center">
                  <img src={drop} height="60px" />
                </div>

                <div className="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-3  px-0">
                  <div className="bd-nearby-group">{item.bloodGroup}</div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-4 text-center  px-0">
                  <div className="bd-nearby-count ">{item.count}</div>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 px-0">
                  <div
                    className={
                      "bd_vr_border " +
                      ((i + 1) % 3 !== 0 && windowWidth > 650 ? "" : "bg-transparent")
                    }
                  ></div>
                </div>
              </div>

              {i !== 6 && i !== 7 && i !== 8 || windowWidth < 650 ? <hr className="bd_hr_border my-0" /> : ''}
            </div>
          );
        })}
      </div>

      <div className="row my-5">
        <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-12  d-flex justify-content-around">
          <button className="btn btn-blue" onClick={() => toggleForm('Donate Blood')}>
            Donate Blood
          </button>
          <button className="btn btn-primary" onClick={() => toggleForm('Request Blood')}>

            Request Blood
          </button>
        </div>




      </div>

    </div>
    <Dialog aria-labelledby="simple-dialog-title" className="qr-code" maxWidth="400"

      onClose={toggleForm} open={formToggle}>
      <DialogTitle id="alert-dialog-title">{`Please install the Mobile App to ${msg}`}</DialogTitle>
      <DialogContent>

        <div className="d-flex justify-content-between app-qr-code">
          <div>
            <div><img src={arrow} />Get Blood</div>
            <div><img src={arrow} />Track Availability</div>
            <div><img src={arrow} />Donate Blood</div>
            <div><img src={arrow} />Save Life!</div>

          </div>
          <img src={qr} height="150" />
        </div>
      </DialogContent>

      <DialogActions className="p-3 dialog-btns">
        <button onClick={()=>toggleForm(msg)} className="btn btn-primary">
          Close
        </button>
        {/* <button onClick={toggleForm} className="btn btn-outline-danger" autoFocus>
          Go to App Store
          </button> */}
      </DialogActions>

    </Dialog>
  </>
  );
}
