import React from 'react'
import Slider1 from '../../assets/images/bag-blood.png';
import PlaystoreLinks from '../PlaystoreLinks';

export default function Slider6() {
    return ( 

<div className="slider2-grid">
<div className="position-relative h-100">
<div className="left-content-div">
      <div className="main-heading"> Blood Banks</div>
      <div className="main-heading main-sub-heading">The Lifeline</div>
      <p>Automated inventory management maintains stock levels. The<br/> intelligent algorithm sends donation requests to eligible<br/> donors. Further more, regulatory compliance is made easy.</p>
   </div>
      <div className="playstore-div">
   <PlaystoreLinks downloadApp={false}/>

  </div>
    </div>
    <div className="slider2-img-div" >
      <img className="slider6-img img-fluid" src={Slider1} />

    </div>


      <div className="slider-slogan">
      <div className="slider-heading">
      #No Stockout </div>
          <div className="slider-subheading"> Blood availability<br/> Assured </div>
          <div className="slider-subheading  slider-subheading1">Compliance Simplified</div>
      </div>

    
  </div>
    )
}




 
