import axios from "axios";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {validateCaptcha} from "react-simple-captcha";
import {bindActionCreators} from "redux";
import {TRIGGER_CONTACT_US, TRIGGER_CONTACT_US_SUCCESS, TRIGGER_REGISTER_FAILED} from "../../actions/actionTypes";
import contactUsAction from "../../actions/contactUsAction";
import ContactUsView from "../../views/ContactUs";
import Footer from "../../views/Footer/Footer";
import Swal from 'sweetalert2'


import check from "../../assets/images/success.png";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
// import 'sweetalert2/src/sweetalert2.scss'

export default function ContactUsContainer() {

  const constactUsObject = {
    firstName: '', lastName: '', subject: '', comment: '', email: '', mobileNumber: ''
  };
  const dispatch = useDispatch();

  const {contactUsObj} = useSelector((state) => state.contactUsReducer);


  const [captchaMatch, setCaptchaMatch] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [contactObj, setContactObject] = useState(constactUsObject);
  const [showPopUp, setPopUp] = useState(false);
  const emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  const mobilePattern = new RegExp(/^[0-9]\d{4,12}$/);
  const [alert, setAlert] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    // doSubmit()
    setIsSubmit(true)
    const url = `${window.apiHost}generic/notified`;
    let user_captcha_value =
      document.getElementById("user_captcha_input").value;
   
    const {
      firstName, lastName, email, mobileNumber, comment, subject
    } = contactObj;



    if (firstName !=="" && lastName!==""  && email!==""   && comment!==""  && subject!=="" && user_captcha_value && (validateCaptcha(user_captcha_value) == true)) {
      // if(){
      //     // alert("Captcha Matched");
         setCaptchaMatch(true);
      //  } else {
      //     // alert("Captcha Does Not Match");
      //    setCaptchaMatch(false)
      //  }
      
      // if(captchaMatch){

        dispatch(contactUsAction(contactObj, TRIGGER_CONTACT_US))
        axios.post(url, contactObj).then(response => {
          resetForm();
          setIsSubmit(false);
          
          dispatch(contactUsAction(response, TRIGGER_CONTACT_US_SUCCESS))
 
  
        })
          .catch(error => {
            dispatch(contactUsAction(error.response, TRIGGER_REGISTER_FAILED))
  
          });
      }
      // }

   
  }

  const onChangeText = (e) => {
    let updateConatctUsObject = {...contactObj};
    updateConatctUsObject[e.target.name] = e.target.value;
    setContactObject(updateConatctUsObject);

  }



  const doSubmit = () => {
    let user_captcha_value =
      document.getElementById("user_captcha_input").value;
    if (validateCaptcha(user_captcha_value) == true) {
      setCaptchaMatch(true);
    } else {
      setCaptchaMatch(false)
    }
  };

  function onChangeCaptcha(value) {
  }


  const resetForm = () => {
    setContactObject(constactUsObject);
    document.getElementById("user_captcha_input").value = "";
    setCaptchaMatch(false)

  }
  return (
    <>

      <div className="app-margin h-100 app-top-margin">
        <ContactUsView emailPattern={emailPattern} onChangeCaptcha={onChangeCaptcha} mobilePattern={mobilePattern} resetForm={resetForm} captchaMatch={captchaMatch} isSubmit={isSubmit} contactObj={contactObj} onSubmit={onSubmit} onChangeText={onChangeText} doSubmit={doSubmit} />
      </div>
      <Footer />

    
    </>
  );
}



function mapDispatchToProps(dispatcher) {
  return bindActionCreators({
    contactUsAction: contactUsAction,
  }, dispatcher)
}
