import React from 'react'
import Slider1 from "../../assets/images/Patient_Care.png";
import PlaystoreLinks from '../PlaystoreLinks';

export default function Slider4View() {
  return (

  <div className="slider4-grid">

<div className="position-relative h-100">
<div className="left-content-div">
        <div className="main-heading"> Hospitals</div>
        <div className="main-heading main-sub-heading"> Save Life</div>
        <p>Saving lives is the next form of creating lives. Doctors & Hospitals<br/> engaged in this noble profession can augment their efficiency<br/> through this platform by requesting blood units in advance.</p>
        </div>
        <div className="playstore-div">
   <PlaystoreLinks downloadApp={false}/>

  </div>
  </div>
  

    <div className="slider4-sec3" >
        <img className="slider4-img img-fluid" src={Slider1} />

      </div>
<div className="slider-slogan">
          <div className="slider-heading">
          #Get Blood </div>
           <div className="slider-subheading"> Post the requirement <br /> in advance and</div>

         <div className="slider-subheading slider-subheading1">  get blood in time</div>
        </div>
  </div>
  )
}


