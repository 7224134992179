import React from 'react';
import angle from '../../assets/images/right-angle.png'

export default function FillGap() {
 
  return (
    <div className="app-padding mt-3 fill-gap">
        <div className="row">

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 row align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <div className="fill-gap-counts">5.2</div>
                <p>Crore units of Blood<br/> needed every year</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center">
                <img src={angle} className="angle img-fluid"/>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <div className="fill-gap-counts ">1.5</div>
                <p>Crore units of Blood<br/> donated every year</p>
            </div>


        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1">
            <div className="main-header-title ">Fill the gap - Save Life!</div>

            <p>Everyday, we lose lots of lives for the lack of timely availability of Blood and Blood Components. There is no dearth of donors, but lacks a dynamic system in place. Situation worsens, when there’s a disaster or calamity demanding 100s of units of blood to be mobilised within a shorter time span.</p>

<p>As per the recent Lancet Report of Feb 2021, India requires ~52 million units of Blood, whereas the demand generation is ~18.5 million units and out of which only ~14.5 million units are fulfilled. This makes about 1 in every 5 patients demanding blood to face the crisis of unavailability.</p>

<p>With the ongoing pandemic, need for blood and its availability is way out of balance. Please donate blood prior to your vaccination and save life.</p>
        </div>
        </div>

 



    </div>
  )
}
