import React, {useEffect, useState} from 'react';
import bag from '../../assets/images/bag.png';
import support from '../../assets/images/support.png';
import neural from '../../assets/images/neural.png';
import register from '../../assets/images/register.png';
import stock from '../../assets/images/stock.png';
import blood from '../../assets/images/blood.png';
import './BloodDonationProcess.css'

export default function BloodDonationProcess() {

  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
    
  const stepsList = [{
    img: register,
    title: 'Register',
    text: 'Register as a user to request / donate blood'
  },
  {
    img: support,
    title: 'Request',
    text: 'Raise a request for blood and search availability nearby'
  },
  {
    img: neural,
    title: 'Algorithm',
    text: 'Sends your request to Blood Banks and eligible donors'
  },
  {
    img: stock,
    title: 'Realtime Stock',
    text: 'Realtime status monitoring ensures Blood availability'
  }, {
    img: blood,
    title: 'Replenishment',
    text: 'Donors replenish stock. Organise Camps and Emergency Relief'
  }
    , {
    img: bag,
    title: 'Save Life',
    text: 'Every life matters and none dies from lack of Blood availability'
  }
  ];
    return (
      <div className="app-margin">
        

        {windowWidth < 959 ?         <div>
        <div className="main-header-title text-center">Blood Donation Process</div>

  { stepsList.map((step, i) => {
return <div className="vertical-list " key={i}>
<div className="step-grid">
  <div> 
  <div className="step-img mb-0 ">
  <img className="center" src={step.img} alt={step.title} />
</div>
{i !==stepsList.length-1 ?       <div className="verticle-bar mx-auto">

</div> : ''}

  </div>

<div className="pt-2 pl-3">
<div className="step-title mb-0">{step.title}</div>
<div className="step-content">{step.text}</div>

  </div>


  </div>
</div>

     

})
}   

  </div> :         <div className=" ">
  <div className="main-header-title ">Blood Donation Process</div>

            <div className="grid">
              
           { stepsList.map((step, i) => {
    return <div className="step " key={i}>
      <div className="">
      <div className="step-img m-auto ">
        <img className="center" src={step.img} alt={step.title} />
      </div>
      <div className="step-title text-center">{step.title}</div>
      <div className="step-content text-center">{step.text}</div>


        </div>

      <div className="step-bar-left"></div>
    </div>

           

  })
}   
            </div>
 
        </div>
}      



      </div>
    )
}


