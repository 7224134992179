import React from "react";
import Google from "../assets/images/Google.png";
import Apple from "../assets/images/Apple.png";

export default function PlaystoreLinks({downloadApp}) {
  return (<div className="mt-4">
  {downloadApp ? 
<p className="my-4" >Download the App now</p> : ''

  }
    <div className="d-flex mt-3ml-1">
      <a href=""> <img className="google mr-2" src={Google} /></a>
     
      <a href=""> <img  className="apple" src={Apple} /> </a>
    </div>
    </div>
  );
}
