import React from 'react'
import Footer from '../Footer/Footer';
import faq from "../../assets/images/faq.png";
import "./faq.css"
import Questions from './Questions';


export default function FaqView() {
    return (
        <>
            <div className="app-margin app-sub-margin faq app-top-margin">
                <div className="row">
                <div className="main-header-title mb-3 col-12 d-block d-lg-none ">FAQ about Blood Donation</div>

                    <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 order-md-2 order-sm-2 order-lg-1 order-xl-1 order-2">
                        <div className="main-header-title mb-3 d-none d-lg-block">FAQ about<br/> Blood Donation</div>
                        <p>WHO says, <b>“Blood is an Essential Medicine”</b>. Incidentally, it is not manufactured<br/> artificially. Blood donation is the most altruistic act that unites humanity closer.<br/>  You don’t have to be a doctor to save lives. With strict regulations on Blood banks,<br/>  Blood Donation is even more safer than earlier. A healthy donor can donate Whole<br/>  Blood, Platelets and Plasma at well equipped blood banks.<br/>
Several medical journals report<b> Blood donation improves the donors’ health</b>.</p>

      

                    </div>
                    <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12 order-md-1 order-sm-1 order-lg-2 order-xl-2 order-1">
                        <img className="img-fluid faq-img" src={faq} />
                    </div>
                </div>




                <Questions />
            </div>

            <Footer />
        </>
    )
}
