import React ,{useLayoutEffect} from "react";
import {Route,useLocation} from "react-router-dom";
import Header from "../../views/Header";


const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const location = useLocation();
    console.log(location.pathname);
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);
    return (
        <>
        <Route
            {...rest}
            render={(props) =>
                <>
                {location.pathname !=="/terms-and-conditions" && location.pathname!=="/privacy-policy.html" ?  <Header history={props.history} /> : ''}

                   
                    <Component {...props} />
                  
          

                </>
            }
        />

        </>

    );

};

export default PublicRoute;
