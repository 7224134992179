import React from 'react'
import PlaystoreLinks from '../PlaystoreLinks';
import mobile from '../../assets/images/mobile.png'

export default function GetTheApp() {
    return (
        <div className="app-margin  app-sub-margin">
            <div className="main-header-title">Get the App</div>
            <div className="row bd_get_the_app ">
                <div className="col-xl-6 col-lg-7 col-md-7 col-sm-12  col-12">
                    <div className="main-header-title  mb-2">Donate Blood - Save Life</div>
                    <p>Intelligent algorithm maps the donors with nearby blood banks to constanttly fulfill the demands. A patient has to just raise the request for blood through this app and the rest is automated until the blood is issued to the patient. With active participation of younger generation in blood donation, we endeavour to make India self sufficient in blood availability by 2022 and the world by 2023.</p>
                    <div className="mb-5">   <PlaystoreLinks downloadApp={true}/></div>
                 
                </div> 
                <div className=" col-xl-5 col-lg-5 col-md-5 col-sm-12  col-12 bd_get_the_app_right position-relative">
                    <img src={mobile}  className="mobile-img img-fluid"/>
                </div>
            </div>
            
        </div>
 
    )
}
