import React from 'react'
import BloodTypeDetails from '../../views/AboutBlood/BloodTypeDetails'
import EligibilityCrieteria from '../../views/AboutBlood/EligibilityCrieteria'
import BloodDonationProcess from '../../views/bloodDonationProcess/BloodDonationProcess'
import Footer from '../../views/Footer/Footer'
import Mobilisation from '../../views/HomePage/Mobilisation'
import Who from '../../views/HomePage/Who'
export default function AboutBloodContainer() {
    return (
        <div className="app-top-margin">
            <BloodTypeDetails />
            {/* <BloodDonationProcess /> */}
            <EligibilityCrieteria />
            <Mobilisation />
            <Footer />

        </div>
    )
}
