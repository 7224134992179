import React, { useEffect } from "react";

export default function ContactUs({
  contactObj,
  onChangeText,
  isSubmit,emailPattern,mobilePattern 
}) {
  const { firstName, lastName, subject, email, mobileNumber, comment} =
    contactObj;


  return (
    <form className="row">
      <div className="col-md-6 col-sm-12 form-group">
        <input
          type="text"
          className={
            "form-control " +
            (isSubmit && firstName === "" ? "error-border" : "")
          }
          placeholder="First name"
          name="firstName"
          value={firstName}
          onChange={onChangeText}
        />
      </div>
      <div className="col-md-6 form-group">
        <input
          className={
            "form-control " +
            (isSubmit && lastName === "" ? "error-border" : "")
          }
          type="text"
          placeholder="Last name"
          name="lastName"
          value={lastName}
          onChange={onChangeText}
        />
      </div>
      <div className="col-md-6 form-group">
        <input
          type="text"
          className={
            "form-control " + (isSubmit && email === "" ? "error-border" : "")
          }
          placeholder="Email Address"
          name="email"
          value={email}
          onChange={onChangeText}
        />
          {isSubmit && email && !emailPattern.test(email) ? <p className="error">Invalid email!</p> : ''}
      </div>
      <div className="col-md-6 form-group">
        <input
          type="number"
          className="form-control"
          placeholder="Phone no."
          name="mobileNumber"
          value={mobileNumber}
          onChange={onChangeText}
        />
        {isSubmit && mobileNumber && !mobilePattern.test(mobileNumber) ? <p className="error">Invalid mobile number!</p> : ''}
      </div>

      <div className="col-12 form-group">
        <select
          placeholder="Subject"
          className="form-control"
          id="exampleFormControlSelect1"
          name="subject"
          className={
            "form-control " + (isSubmit && subject === "" ? "error-border" : "")
          }
          value={subject}
          onChange={onChangeText}
        >    <option disabled  value="">Subject </option>

          <option>Report a Concern</option>
          <option>Share your Feedback
</option>
          <option>Suggest a Feature</option>
        </select>
        <div className="invalid-feedback">Please select a valid state.</div>
      </div>
      <div className="col-12 form-group">
        <textarea
          className="form-control"
          value={comment}
          onChange={onChangeText}
          placeholder="Enter your message"
          id="exampleFormControlTextarea1"
          rows="5"
          name="comment"
          className={
            "form-control " + (isSubmit && comment === "" ? "error-border" : "")
          }
        ></textarea>
      </div>
    </form>
  );
}
