import React, { Component } from 'react'
import PublicRoute from './utils/routes/publicRoutes';
import { BrowserRouter, Switch } from "react-router-dom";
import {publicRoutesList} from './utils/routes/routeConfig';
import "./assets/css/style.css";
import History from './history/History';



export default class App extends Component {
  render() {
    
    return (
      <div>
        <article className="reader-view-content">
  <p>
  BLUD is a structured system for coordinated and sustainable sourcing of Blood, aimed to achieve Self Sufficiency in Blood Availability. Globally, attaining self-sufficiency in blood availability is a growing concern. The App is a step closer to automate this ‘Request to Donation cycle’ efficiently. By networking Hospitals, Blood Banks and Donors, we aim to reduce the lead-time from request to blood availability and also reduce the excess inventory carriage and wilful wastage (end of life Disposal).
  </p>
   <p>  Intelligent Inventory Management System sends pulse to eligible donors nearby mobilising donation to maintain optimum stock levels. Automation maintains traceability and compliance right from sustainable sourcing of blood to ensuring its continued availability in times of need. This App can only connect with willing and eligible donors, who Volunteer to Donate Blood for Free without any monetary expectations.</p>
    
  <p> However, Blood Centres (Banks) and respective recipient medical or research institutions are responsible for examining and ascertaining the medical fitness of donors volunteering for Blood donation.</p>
    
    <p>Come, let us join hands together to save lives!</p>
    
  </article>
         <BrowserRouter history={History}>
        <Switch>

          {publicRoutesList.map((routes, index) => {
            return (
              <PublicRoute  history={History}
                key={index}
                restricted={routes.restricted}
                component={routes.component}
                path={routes.path}
                exact
              />
            );
          })}
      
        </Switch>
      </BrowserRouter>
      </div>
    )
  }
}

