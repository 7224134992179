import React from 'react'
import Slider3 from '../../assets/images/girl.png';
import PlaystoreLinks from '../PlaystoreLinks';

export default function Slider3View() {
  return (<>

    <div className="slider2-grid">
    <div className="position-relative h-100">
    <div className="left-content-div">
        <div className="main-heading">Chronic <br /> <span className="primary-color">Illness</span></div>
        <p>We understand the plight of the families of Thalassaemia /<br /> Hemophilia patient. No more worries. Just complete your<br /> medical profile in the App. Donors will be lined up on schedule.</p>
        </div>
        <div className="playstore-div">
   <PlaystoreLinks downloadApp={false}/>

  </div>
      </div>
      <div className=" slider2-img-div text-center">
            <img className="slider3-img img-fluid" src={Slider3} />

          </div>
            <div className="slider-slogan">

              <div className="slider-heading primary-color">  #No more Struggle </div>
              <div className="slider-subheading primary-black"> GET BLOOD FOR SURE</div>

            </div>


    </div>
  </>
  )
}
