import {useHistory} from "react-router-dom";
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import bloodGroups from '../../assets/images/BloodGroups.png';
import Dialog from '@material-ui/core/Dialog';
import {useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';


export default function BloodTypeDetails() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleClick = () => {
    history.push("/faq/Eligibility");
  }
  return (<>
    <div className="app-margin app-sub-margin">
      <div className="row  ">
      <div className="main-header-title mb-3 col-12 d-block d-xl-none">Know about your
            Blood type</div>
        <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 order-md-2 order-lg-1 order-xl-1 order-sm-2 order-2">
          <div className="main-header-title mb-3 d-none d-xl-block">Know about your<br />
            Blood type</div>

          <p>Due to advancement in medical technologies, whole blood drawn from a healthy<br /> donor is separated into Red Cells, Plasma and Platelets. These blood components<br /> are then stored in controlled temperature for transfusion medicine as treatment.</p>
          <p>We all know the various types of Blood grouped as A, B, AB and O. These also are<br /> further segregated as RH Positive (+) and RH Negative (-). O type Blood is known as<br /> the ‘Universal Donor’ meaning that it can be given to any other blood type.<br /> Similarly, AB type Blood is known as ‘Universal Receptor’ as it can receive blood<br /> from any [A,B,AB or O] Blood group.</p>
          <p>You can understand from the table here the compatibility of various Blood<br /> components for your Blood type.</p>
          {/* <button type="button" className="btn btn-primary my-4" onClick={handleClick}> */}
            {/* View Eligibility</button> */}
        </div>
        <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12 text-center order-md-1 order-lg-1 order-xl-1 order-sm-1 order-1">
          <img className="img-fluid blood-type-img cursor-pointer" src={bloodGroups} onClick={handleClickOpen} />
        </div>

      </div>
    </div>

    <Dialog fullWidth={fullScreen} maxWidth={"md"} className="blud-type-dialog"
      onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <img className="img-fluid pop-up-blood" src={bloodGroups} />
    </Dialog>
  </>
  )
}
